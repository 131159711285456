import store from '../store'
import backendDialog from './backendDialog'
import config from '../../config'

const apiURL = config.API_URL
const idleAnimationFrames = [1, 2, 3, 4]
const directions = ["up", "down", "left", "right"]

const dungeonLayers = ["ground", "preview", "front"]
const objects = [
  "traps/0", "traps/1", "traps/2",
  "spawns/blue", "spawns/red", "spawns/monster",
  "chests/0"
]
const headingObjectsAnimations = [{ name: "arrow", frames: 4 }]
const objectsAnimations = [{ name: "bomb", frames: 4 }, { name: "dynamite", frames: 4 }]
const otherAnimations = [{ name: "apparationSymbol", frames: 3 }, { name: "chestOpen", frames: 3 }]
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
var images = {}

export default {
  loadAllAssets() {
    return backendDialog.getAssets().then(assets => {

      // Dungeons
      assets.dungeons.forEach(d => {
        dungeonLayers.forEach(layer => {
          images["dungeons/" + d + "/" + layer] = this.loadImage("dungeons/" + d + "/" + layer + ".png")
        });
      });

      // Avatars
      assets.avatars.forEach(a => {
        // preview
        images["avatars/" + a + "/preview"] = this.loadImage("avatars/" + a + "/preview.png")

        idleAnimationFrames.forEach(i => {
          images["avatars/" + a + "/idle/left/" + i] = this.loadImage("avatars/" + a + "/idle/left/" + i + ".png")
          images["avatars/" + a + "/idle/right/" + i] = this.loadImage("avatars/" + a + "/idle/right/" + i + ".png")
        });
      });

      // Monsters
      assets.monsters.forEach(m => {
        // preview
        images["monsters/" + m + "/preview"] = this.loadImage("monsters/" + m + "/preview.png")

        idleAnimationFrames.forEach(i => {
          images["monsters/" + m + "/idle/left/" + i] = this.loadImage("monsters/" + m + "/idle/left/" + i + ".png")
          images["monsters/" + m + "/idle/right/" + i] = this.loadImage("monsters/" + m + "/idle/right/" + i + ".png")
        });
      });

      // Animations
      assets.animations.forEach(a => {
        a.forEach(f => {
          images["animations/" + f] = this.loadImage("animations/" + f + ".png")
        });
      });

      headingObjectsAnimations.forEach(a => {
        directions.forEach(d => {
          for (let i = 1; i <= a.frames; i++) {
            let name = "objects/" + a.name + "/" + d + "/" + i
            images[name] = this.loadImage(name + ".png")
          }
        });
      });

      // bomb & dyna objectsAnimations
      objectsAnimations.forEach(a => {
        [3, 2, 1].forEach(l => {
          for (let i = 1; i <= a.frames; i++) {
            let name = "objects/" + a.name + "/" + l + "/" + i
            images[name] = this.loadImage(name + ".png")
          }
        });
      });

      // Statics images
      objects.forEach(obj => {
        images["objects/" + obj] = this.loadImage("objects/" + obj + ".png")
      });
      otherAnimations.forEach(obj => {
        for (let i = 1; i < obj.frames + 1; i++) {
          images["animations/" + obj.name + "/" + i] = this.loadImage("animations/" + obj.name + "/" + i + ".png")
        }
      });

    })
  },
  loadImage(url) {
    let img = new Image();
    img.src = apiURL + "images/" + url

    return img
  },
  getImage(path) {
    if (images[path] == undefined) {
      console.error("Image non trouvée");
      console.error(path);
      console.error(images);

      return false
    }
    return images[path]
  },
  getAnimationImage(path) {
    if (images[path] == undefined) {

      return false
    }
    return images[path]
  },
  isMobile,

  prettyTimeStamp(ts) {
    // Seconds
    let sec = (Date.now() - ts) / 1000
    if (sec < 10) return "Just now"
    if (sec < 60) return Math.floor(sec) + " sec ago"
    // Minutes
    let min = sec / 60
    if (min < 60) return Math.floor(min) + " min ago"
    // Hours
    let h = min / 60
    if (Math.floor(h) == 1) return Math.floor(h) + " hour ago"
    if (h < 24) return Math.floor(h) + " hours ago"
    // Days
    let days = h / 24
    if (Math.floor(days) == 1) return Math.floor(days) + " day ago"
    if (days < 30) return Math.floor(days) + " days ago"
    // mounths
    let mounths = days / 30;
    if (mounths < 12) return Math.floor(mounths) + " mounth ago"
    // years
    let years = mounths / 12;
    if (Math.floor(years) == 1) return Math.floor(days) + " year ago"
    return Math.floor(years) + " years ago"

  },

  nameCorrect(name) {
    if (name.length < 2) {
      store.commit("sendMessage", {
        type: "error",
        text: "We dont do short names here"
      });
      return false;
    }
    if (name.length > 15) {
      store.commit("sendMessage", {
        type: "error",
        text: "We dont do long names here"
      });
      return false;
    }
    if (name.replace(/ /g, "").length == 0) {
      store.commit("sendMessage", {
        type: "error",
        text: "We dont do weird names here"
      });
      return false;
    }

    return true;
  }
}