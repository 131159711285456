import axios from 'axios'
import config from '../../config'
import store from '../store'

const apiURL = config.API_URL
console.log("Backend url : " + apiURL);

function startRequest(name) {
  let c = Math.random();
  store.commit("startRequest", { name, code: c })
  return c
}

function endRequest(code) {
  store.commit("endRequest", code)
}

function getClientId() {
  let cId = store.state.clientId

  if (cId == undefined || cId == null) {
    store.commit("sendMessage", {
      type: "error",
      text: "The server is unreachable"
    });
    throw "Error, no client ID"
  }
  return cId
}
function getAccountId() {
  return store.state.account.id
}
function getGameId() {
  return store.state.gameId
}
function getGameType() {
  return store.state.gameType
}

export default {

  // login & register
  playAsGuest(data) {
    let code = startRequest("Login in")
    data.clientId = getClientId()
    return axios.post(apiURL + 'playAsGuest', data).finally(() => {
      endRequest(code)
    }).then((response) => {
      return response.data
    })
  },
  login(data) {
    let code = startRequest("Login in")
    data.clientId = getClientId()
    return axios.post(apiURL + 'login', data).finally(() => {
      endRequest(code)
    }).then((response) => {
      return response.data
    })
  },

  tokenLogin(data) {
    let code = startRequest("Checking token")
    data.clientId = getClientId()
    return axios.post(apiURL + 'tokenLogin', data).finally(() => {
      endRequest(code)
    }).then((response) => {
      return response.data
    }).catch(e => {
      console.warn("Token login failed")
      return false
    })
  },

  register(data) {
    let code = startRequest("Registering")
    data.clientId = getClientId()
    return axios.post(apiURL + 'register', data).finally(() => {
      endRequest(code)
    }).then((response) => {
      return response.data
    })
  },
  disconnect() {
    let code = startRequest("Disconnecting")
    let data = { clientId: getClientId() }
    return axios.post(apiURL + 'disconnect', data).finally(() => {
      endRequest(code)
    }).then((response) => {
      return response.data
    })
  },

  // load Assets
  getNbOnline() {
    return axios.post(apiURL + 'getNbOnline', { clientId: getClientId(), accountId: getAccountId() })
      .then((response) => {
        return response.data
      });
  },
  getAssets() {
    let code = startRequest("Loading game assets")
    return axios.get(apiURL + 'assets').finally(() => {
      endRequest(code)
    }).then((response) => {
      return response.data
    }).catch(e => {
      store.commit("sendMessage", {
        type: "error",
        text: "Error while loading game asets"
      });
      console.error(e);
      throw e
    });
  },
  getDungeonMaps() {
    let code = startRequest("Loading maps")
    return axios.get(apiURL + 'maps').finally(() => {
      endRequest(code)
    }).then((response) => {
      return response.data
    }).catch(e => {
      store.commit("sendMessage", {
        type: "error",
        text: "Error while loading dungeon maps"
      });
      console.error(e);
      throw e
    });
  },
  getDungeonMap(mapId) {
    let code = startRequest("Loading map")
    return axios.get(apiURL + 'map/' + mapId)
      .finally(() => endRequest(code))
      .then((response) => response.data)
  },

  // createGame
  createGame(gameMode, mapId) {
    let code = startRequest("Creating game")
    return axios.post(apiURL + 'createGame', { clientId: getClientId(), gameMode, mapId }).finally(() => {
      endRequest(code)
    }).then((response) => {
      return response.data
    }).catch(e => {
      store.commit("sendMessage", {
        type: "error",
        text: "Error while creating game"
      });
      console.error(e);
      throw e
    });
  },
  leaveGame() {
    return axios.post(apiURL + 'leaveGame', {
      clientId: getClientId(),
      gameId: getGameId(),
      gameType: getGameType(),
    }).finally(() => {
    }).catch(() => { })
  },

  // game
  askForMovement(dx, dy) {
    let code = startRequest("Sending movements")
    return axios.post(apiURL + 'askForMovement', {
      clientId: getClientId(),
      gameId: getGameId(),
      gameType: getGameType(),
      dx,
      dy
    }).finally(() => {
      endRequest(code)
    }).then((response) => {
      return response
    })
  },
  askForItemSelect(item) {
    let code = startRequest("Sending item selection")
    return axios.post(apiURL + 'askForItemSelect', {
      clientId: getClientId(),
      gameId: getGameId(),
      gameType: getGameType(),
      item
    }).finally(() => {
      endRequest(code)
    }).then((response) => {
      return response
    })
  },

  // multyplayer
  getLobby() {
    let code = startRequest("Loading lobby")
    return axios.get(apiURL + 'lobby')
      .finally(() => endRequest(code))
      .then((response) => response)
  },
  joinLobby(name, isPrivate) {
    let code = startRequest("Joining a lobby")
    return axios.post(apiURL + 'joinLobby', { clientId: getClientId(), name, isPrivate })
      .finally(() => endRequest(code))
      .then((response) => response)
  },
  leaveLobby(name) {
    let code = startRequest("Leaving a lobby")
    return axios.post(apiURL + 'leaveLobby', { clientId: getClientId(), name })
      .finally(() => endRequest(code))
      .then((response) => response)
  },
  changeTeam(name, team) {
    let code = startRequest("Changing team")
    return axios.post(apiURL + 'lobby/team', { clientId: getClientId(), name, team })
      .finally(() => endRequest(code))
      .then((response) => response)
  },
  selectLobbyMap(name, mapId) {
    let code = startRequest("Select map")
    return axios.post(apiURL + 'lobby/map', { clientId: getClientId(), name, mapId })
      .finally(() => endRequest(code))
      .then((response) => response)
  },
  startLobby(name) {
    let code = startRequest("Starting the lobby")
    return axios.post(apiURL + 'startLobby', { clientId: getClientId(), name })
      .finally(() => endRequest(code))
      .then((response) => response)
  },
  getLobbyGame(name) {
    let code = startRequest("Loading the game")
    return axios.post(apiURL + 'getLobbyGame', { clientId: getClientId(), name })
      .finally(() => endRequest(code))
      .then((response) => response.data)
  },


  // props
  getProps() {
    let code = startRequest("Getting suggestions")
    return axios.get(apiURL + 'props').finally(() => {
      endRequest(code)
    }).then((response) => {
      return response.data
    }).catch(e => {
      store.commit("sendMessage", {
        type: "error",
        text: "Error while getting suggestions"
      });
      console.error(e);
      throw e
    });
  },
  sendProp(prop) {
    prop.clientId = getClientId()
    prop.accountId = getAccountId()

    let code = startRequest("Sending suggestion")
    return axios.post(apiURL + 'props', prop).finally(() => {
      endRequest(code)
    }).catch(e => {
      store.commit("sendMessage", {
        type: "error",
        text: "Error while sending suggestion"
      });
      console.error(e);
      throw e
    });
  },
  likeProp(propKey) {
    let code = startRequest("Sending Like")
    return axios.post(apiURL + 'likeProp', { propKey, clientId: getClientId(), accountId: getAccountId() }).finally(() => {
      endRequest(code)
    }).catch(e => {
      store.commit("sendMessage", {
        type: "error",
        text: "Error while sending Like"
      });
      console.error(e);
      throw e
    });
  },

  // AI
  getModels() {
    let code = startRequest("Loading models")
    return axios.get(apiURL + 'models').finally(() => {
      endRequest(code)
    }).then((response) => {
      return response.data
    }).catch(e => {
      store.commit("sendMessage", {
        type: "error",
        text: "Error while loading models"
      });
      console.error(e);
      throw e
    });
  },
  selectModel(modelId) {
    return axios.post(apiURL + 'selectModel', {
      clientId: getClientId(),
      gameId: getGameId(),
      gameType: getGameType(),
      modelId,
    }).finally(() => {
    }).catch(() => { })
  },
  retryDemo() {
    return axios.post(apiURL + 'retryDemo', {
      clientId: getClientId(),
      gameId: getGameId(),
      gameType: getGameType(),
    }).finally(() => {
    }).catch(() => { })
  },
  setDemoSpeed(speedText) {
    let code = startRequest("setting speed")
    return axios.post(apiURL + 'setDemoSpeed', {
      speedText,
      clientId: getClientId(),
      gameId: getGameId(),
      gameType: getGameType(),
    }).finally(() => {
      endRequest(code)
    }).then((response) => {
      return response.data
    }).catch(e => {
      store.commit("sendMessage", {
        type: "error",
        text: "Error while setting the demo speed"
      });
      console.error(e);
      throw e
    });
  },
  askForDemoMovement() {
    let code = startRequest("Waiting for the bot to move")
    return axios.post(apiURL + 'askForDemoMovement', {
      clientId: getClientId(),
      gameId: getGameId(),
      gameType: getGameType(),
    }).finally(() => {
      endRequest(code)

    }).then((response) => {
      return response
    })
  },
}