<template>
  <div id="map">
    <!-- Learderboard Modal -->
    <Modal v-if="showLeaderboard">
      <Leaderboard
        :scores="{ solo: map.soloScores, coop: map.coopScores }"
        :selectedScorePannel="selectedScorePannel"
        v-on:exit="showLeaderboard = false"
      />
    </Modal>

    <!-- Map image, name and play btn -->
    <div id="mapInfo">
      <div id="mapHeader">
        <div id="mapTitle">{{ map.name }}</div>
        <img
          :src="$API_URL + 'images/dungeons/' + map.id + '/preview.png'"
          alt
        />
      </div>
      <!-- Buttons -->
      <div id="buttons" v-if="playBtn">
        <button :class="'green ' + (selected ? 'selected' : '')" @click="play">
          Play
        </button>
        <button class="blue" @click="demoAi">AI Demo</button>
      </div>
    </div>
    <!-- Scores -->
    <fieldset id="scores">
      <legend>
        Scores
        <button
          id="leadBtn"
          @click="showLeaderboard = !showLeaderboard"
          class="blue"
        >
          Leaderboard
        </button>
      </legend>

      <!-- Pannel selection : -->
      <span>
        <!-- Solo -->
        <button v-if="selectedScorePannel == 1" class="blue">Solo</button>
        <button v-else @click="selectedScorePannel = 1">Solo</button>

        <!-- 2 players -->
        <button v-if="selectedScorePannel == 2" class="blue">2 Players</button>
        <button v-else @click="selectedScorePannel = 2">2 Players</button>

        <!-- 3 players -->
        <button v-if="selectedScorePannel == 3" class="blue">3 Players</button>
        <button v-else @click="selectedScorePannel = 3">3 Players</button>

        <!-- 4 players -->
        <button v-if="selectedScorePannel == 4" class="blue">4 Players</button>
        <button v-else @click="selectedScorePannel = 4">4 Players</button>
      </span>

      <!-- Score display : -->
      <table v-if="selectedScorePannel == 1">
        <!-- Personal best -->
        <tr>
          <td class="scoreSectionTitle">Personal :</td>
          <td class="scoreSection">
            <score
              v-if="$store.state.account !== null"
              :score="
                this.map.soloScores.find(
                  (s) => s.accountId == $store.state.account.id
                )
              "
            />
            <div v-else style="color: red">Not logged yet</div>
          </td>
        </tr>
        <!-- Best overal -->
        <tr>
          <td class="scoreSectionTitle">Best :</td>
          <td class="scoreSection">
            <score :score="map.soloScores[0]" />
          </td>
        </tr>
        <!-- latest -->
        <tr>
          <td class="scoreSectionTitle">Latest :</td>
          <td class="scoreSection">
            <score
              :score="
                this.map.soloScores.length > 0
                  ? this.map.soloScores.reduce((a, b) =>
                      a.timeStamp > b.timeStamp ? a : b
                    )
                  : null
              "
            />
          </td>
        </tr>
      </table>
      <table
        v-if="
          selectedScorePannel > 1 && this.map.coopScores[selectedScorePannel]
        "
      >
        <!-- Best overal -->
        <tr>
          <td class="scoreSectionTitle">Best :</td>
          <td class="scoreSection">
            <score :score="map.coopScores[selectedScorePannel][0]" />
          </td>
        </tr>
        <!-- latest -->
        <tr>
          <td class="scoreSectionTitle">Latest :</td>
          <td class="scoreSection">
            <score
              :score="
                this.map.coopScores[selectedScorePannel].length > 0
                  ? this.map.coopScores[selectedScorePannel].reduce((a, b) =>
                      a.timeStamp > b.timeStamp ? a : b
                    )
                  : null
              "
            />
          </td>
        </tr>
      </table>
    </fieldset>
  </div>
</template>

<script>
import score from "./Score";
import Leaderboard from "./Leaderboard";

export default {
  data() {
    return {
      showLeaderboard: false,
      selectedScorePannel: 1,
    };
  },
  components: { score, Leaderboard },
  props: {
    map: { type: Object, requiered: true },
    selected: { type: Boolean, default: false },
    playBtn: { type: Boolean, default: true },
    defaultScorePannel: { type: Number, default: 1 },
  },
  created() {
    this.selectedScorePannel = this.defaultScorePannel;
  },
  methods: {
    play() {
      this.$emit("play");
    },
    demoAi(){
      this.$emit("demoAi");
    }
  },
  computed: {},
  watch: {
    defaultScorePannel() {
      this.selectedScorePannel = this.defaultScorePannel;
    },
  },
};
</script>

<style scoped>
#map {
  display: flex;
  margin: 1vh 0 1vh 0;
  background-color: rgb(73, 73, 73);
}
#mapInfo {
  background-color: gray;
  box-shadow: inset 0px 0px 5px 0px black;
  display: flex;
  justify-content: center;
  padding: 1vh;
}
#mapHeader {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  background-color: black;
}
#buttons {
  display: flex;
  flex-direction: column;
}
#buttons .green {
  flex: 1;
}
#mapTitle {
  width: 100%;
  background-color: #44607c;
  box-shadow: inset 0 0 1vh 0.1vh black;
  /* overflow: hidden; */
}
img {
  flex: 1;
  max-width: 35vh;
  max-height: 35vh;
  padding: 0.5vh;
  padding-bottom: 2vh;
  /* border-radius: 2vh; */
}

button.selected {
  transform: scale(1.05);
  background: rgb(0, 184, 0);
}

/* Scores */

#scores {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: solid white 0.2vh;
  padding: 2vh;
  margin: 2vh;
}
.scoreSectionTitle {
  text-align: right;
  padding-right: 1vh;
  white-space: nowrap;
}
.scoreSection {
  width: 100%;
}

#leadBtn {
  font-size: 1.5vh;
  padding: 0.5vh;
}

/* // Mobile  */

@media screen and (orientation: portrait) {
  #map {
    flex-direction: column;
  }
  #scores {
    flex-direction: column;
  }
  #scores {
    padding: 1vh;
    margin: 0vh;
  }
}
</style>