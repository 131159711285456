<template>
  <div id="app">
    <Header v-if="!routsWithNoHeader.includes($route.name)" />
    <div id="content">
      <!-- <MusicPlayer /> -->
      <AssetLoading />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
      <Messenger />
      <Loading />
    </div>
    <!-- <Footer v-if="!routsWithNoHeader.includes($route.name)" /> -->
  </div>
</template>

<script>
import "./assets/css/main.css";

import Messenger from "./components/utils/Messenger";
import Loading from "./components/utils/Loading";
import Header from "./components/utils/Header";
// import MusicPlayer from "./components/utils/MusicPlayer";
// import Footer from "./components/utils/Footer";

import AssetLoading from "./components/versync/AssetLoading";

export default {
  name: "App",
  components: {
    Messenger,
    Loading,
    Header,
    // MusicPlayer,
    AssetLoading,
  },
  data: () => {
    return {
      routsWithNoHeader: ["Welcome", "Board"],
    };
  },
  created() {},
  mounted() {
    // play menu music
    this.$musics.play("menu");
  },
  methods: {
    logWithToken() {
      // Check if user token in cache
      var userToken = localStorage["userToken"];

      if (userToken !== null) {
        // check with server
        this.$backendDialog
          .tokenLogin({ token: userToken })
          .then((c) => {
            if (!c || c === "Token expiered") localStorage["userToken"] = null;
            else {
              // Login success
              console.log("Token Valid");
              this.$store.commit("setAccount", c.account);
              this.$store.commit("sendMessage", {
                type: "success",
                text: "Welcome back !",
              });
            }
          })
      }
    },
  },
  sockets: {
    setClientId: function (id) {
      this.$store.commit("setClientId", id);
      this.logWithToken();
    },
    serverMessage: function (msg) {
      this.$store.commit("sendMessage", { type: "info", text: msg });
    },
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || "Versync";
    },
  },
  beforeDestroy() {
    this.$musics.stop();
  },
};
</script>

<style scoped>
#app {
  height: 100%;
  display: flex;
  flex-direction: column;

  text-align: center;
  color: white;
  background-color: var(--primary);
}
#content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  z-index: 1;
}

/* Router Animation */
.fade-enter-active .fade-leave-active {
  transition: opacity 4s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
