var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:'player ' + _vm.diplomacy},[_c('u',[_vm._v(_vm._s(_vm.player.playerName))]),_c('span',{staticClass:"team"},[_vm._v("Team : "+_vm._s(_vm.player.team))]),(_vm.player.dead)?_c('div',{attrs:{"id":"deadSymbol"}},[_vm._v("☠")]):_vm._e(),_c('div',{attrs:{"id":"inventory"}},[_c('div',{class:'itemType' +
        (_vm.player.selectedItem == 'arrow' ? ' selected' : '') +
        (!_vm.player.inventory.arrow ? ' empty' : '')},[_c('div',{staticClass:"itemNumber"},[_vm._v(_vm._s(_vm.player.inventory.arrow))]),_c('img',{class:_vm.diplomacy == 'self' ? 'icon selectable' : 'icon',attrs:{"src":_vm.$API_URL + 'images/objects/arrow/inventoryIcon.png',"title":"Arrow"},on:{"click":function($event){return _vm.itemClick('arrow')}}}),(_vm.diplomacy == 'self' && !_vm.$services.isMobile)?_c('div',{staticClass:"key"},[_vm._v(" 1 ")]):_vm._e()]),_c('div',{class:'itemType' +
        (_vm.player.selectedItem == 'bomb' ? ' selected' : '') +
        (!_vm.player.inventory.bomb ? ' empty' : '')},[_c('div',{staticClass:"itemNumber"},[_vm._v(_vm._s(_vm.player.inventory.bomb))]),_c('img',{class:_vm.diplomacy == 'self' ? 'icon selectable' : 'icon',attrs:{"src":_vm.$API_URL + 'images/objects/bomb/inventoryIcon.png',"title":"Bomb"},on:{"click":function($event){return _vm.itemClick('bomb')}}}),(_vm.diplomacy == 'self' && !_vm.$services.isMobile)?_c('div',{staticClass:"key"},[_vm._v(" 2 ")]):_vm._e()]),_c('div',{class:'itemType' +
        (_vm.player.selectedItem == 'dynamite' ? ' selected' : '') +
        (!_vm.player.inventory.dynamite ? ' empty' : '')},[_c('div',{staticClass:"itemNumber"},[_vm._v(_vm._s(_vm.player.inventory.dynamite))]),_c('img',{class:_vm.diplomacy == 'self' ? 'icon selectable' : 'icon',attrs:{"src":_vm.$API_URL + 'images/objects/dynamite/inventoryIcon.png',"title":"Dynamite"},on:{"click":function($event){return _vm.itemClick('dynamite')}}}),(_vm.diplomacy == 'self' && !_vm.$services.isMobile)?_c('div',{staticClass:"key"},[_vm._v(" 3 ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }