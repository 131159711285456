<template>
  <div id="Lobby">
    <!-- Map selection modal -->
    <modal v-show="mapSelection">
      <LobbyMapSelection
        :defaultScorePannel="this.lobby ? this.lobby.lobbyPlayers.length : 1"
        @cancel="mapSelection = false"
        @select="mapSelect"
      />
    </modal>
    <h1>Multiplayer lobby</h1>
    <div v-if="!lobby" id="lobbyInfo">
      <button class="red" @click="$router.push('/Multiplayer')">Leave</button>
      Loading lobby informations
    </div>
    <div v-else id="lobbyInfo">
      <button class="red" @click="leave()">Leave</button>
      <span> name : {{ lobby.name }} </span>
      <span> gamemode: {{ lobby.gameMode }} </span>
      <span> {{ lobby.isPrivate ? "Private " : "Public " }} lobby</span>
      <span> number of player : {{ lobby.lobbyPlayers.length }} </span>
      <span v-if="lobby.started" style="color: red">
        The game is in progress</span
      >
      <span v-else> The game hasn't started yet</span>
      <button v-if="weAreOwner" class="blue" @click="mapSelection = true">
        select map
      </button>
      <span v-if="selectedMap">
        Selected map :
        <Map
          :map="selectedMap"
          :playBtn="false"
          :defaultScorePannel="lobby.lobbyPlayers.length"
        />
        <button v-if="weAreOwner" class="green" @click="start">Start</button>
      </span>
      <span v-else> No selected map </span>

      <!-- Invitation link -->
      <span>
        Invitation link :
        <input id="link" :value="invitationLink" />
        <button @click="copyLink()" class="blue"><b>Copy</b></button>
      </span>

      Players :
      <ul id="players">
        <LobbyPlayer
          v-for="(lobbyPlayer, i) in lobby.lobbyPlayers"
          :key="i"
          :lobbyName="lobby.name"
          :lobbyOwnerPublicId="lobby.ownerPublicId"
          :lobbyPlayer="lobbyPlayer"
        >
        </LobbyPlayer>
      </ul>
    </div>
  </div>
</template>

<script>
import LobbyPlayer from "./LobbyPlayer";
import LobbyMapSelection from "./LobbyMapSelection";
import Map from "../map/Map";

export default {
  components: { LobbyPlayer, LobbyMapSelection, Map },
  data() {
    return {
      lobby: null,
      mapSelection: false,
      selectedMap: null,
      invitationLink: null,
      isLeaveSecure: false,
    };
  },
  mounted() {
    if (this.connnected) this.getLobby();
  },
  methods: {
    getLobby() {
      this.$backendDialog
        .joinLobby(
          this.$route.params.name,
          this.$route.query?.isPrivate === "true"
        )
        .then((lobby) => {
          this.lobby = lobby.data;
          this.invitationLink =
            window.location.origin + "/#/Multiplayer/Lobby/" + this.lobby.name;
          this.loadMap();
        })
        .catch((e) => {
          if (e.response && e.response.status == 402) {
            this.$store.commit("sendMessage", {
              type: "error",
              text: "The lobby is full",
            });
          }
          this.$router.push("/Multiplayer");
        });
    },
    leave() {
      this.isLeaveSecure = true;

      this.$backendDialog
        .leaveLobby(this.lobby.name)
        .catch(() => {})
        .finally(() => this.$router.push("/Multiplayer"));
    },
    mapSelect(mapId) {
      this.selectedMapId = mapId;
      this.$backendDialog
        .selectLobbyMap(this.lobby.name, this.selectedMapId)
        .finally(() => {
          this.mapSelection = false;
        });
    },
    loadMap() {
      if (this.lobby.selectedMapId)
        this.$backendDialog
          .getDungeonMap(this.lobby.selectedMapId)
          .then((map) => {
            this.selectedMap = map;
          });
    },
    start() {
      this.$backendDialog.startLobby(this.lobby.name).catch((e) => {
        if (e.response && e.response.status == 401) {
          this.$store.commit("sendMessage", {
            type: "error",
            text: "One of the players ins't ready",
          });
        } else this.$router.push("/Multiplayer");
      });
    },
    copyLink() {
      /* Get the text field */
      var copyText = document.getElementById("link");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");

      /* Alert the copied text */
      this.$store.commit("sendMessage", {
        type: "info",
        text: "Invitation link copied to clipboard",
      });
    },
  },
  computed: {
    connnected() {
      return this.$store.state.clientId !== null;
    },
    ourPlayer() {
      if (!this.lobby) return null;
      return this.lobby.lobbyPlayers.find((p) => p.client.id !== undefined);
    },
    weAreOwner() {
      if (!this.ourPlayer) return false;
      return this.ourPlayer.publicId === this.lobby.ownerPublicId;
    },
  },
  watch: {
    connnected() {
      if (this.connnected && !this.lobby) this.getLobby();
    },
  },
  sockets: {
    newLobbyPLayer: function (lobbyPlayers) {
      if (this.lobby) {
        this.lobby.lobbyPlayers = lobbyPlayers;
        this.$store.commit("sendMessage", {
          type: "info",
          text: "A player has join",
        });
      }
    },
    lobbyPLayerLeft: function (lobbyPlayers) {
      if (this.lobby) {
        this.lobby.lobbyPlayers = lobbyPlayers;
        this.$store.commit("sendMessage", {
          type: "info",
          text: "A player has left",
        });
      }
    },
    ownerHasLeave: function () {
      this.$store.commit("sendMessage", {
        type: "info",
        text: "The owner has left",
      });
      this.$router.push("/Multiplayer");
    },
    teamChange: function ({ id, team }) {
      if (this.lobby) {
        let p = this.lobby.lobbyPlayers.find((p) => p.publicId === id);
        if (p) p.team = team;
      }
    },
    gameModeChange: function (gameMode) {
      if (this.lobby) this.lobby.gameMode = gameMode;
    },
    mapChange: function (mapId) {
      this.lobby.selectedMapId = mapId;
      this.loadMap();
    },
    gameStarted: function () {
      if (this.lobby) {
        this.isLeaveSecure = true;
        this.$backendDialog.getLobbyGame(this.lobby.name).then((game) => {
          this.$store.commit(
            "setGameMode",
            "/Multiplayer/Lobby/" + this.lobby.name
          );
          this.$store.commit("setGameType", "multiplayer");
          this.$store.commit("setGameId", game.id);
          this.$router.push({ name: "Board", params: { game } });
        });
      }
    },
    gameEnded: function () {
      if (this.lobby) this.lobby.started = false;

      this.$store.commit("sendMessage", {
        type: "info",
        text: "The game has ended",
      });
    },
    playerReady: function (playerPublicId) {
      if (this.lobby) {
        let p = this.lobby.lobbyPlayers.find(
          (p) => p.publicId === playerPublicId
        );
        if (p) p.ready = true;
      }
    },
  },
  beforeDestroy() {
    if (!this.isLeaveSecure)
      this.$backendDialog.leaveLobby(this.lobby.name).catch(() => {});
  },
};
</script>

<style scoped>
#lobbyInfo {
  display: flex;
  flex-direction: column;
}
#players {
  display: flex;
  flex-direction: column;
  border: solid 1px white;
  padding: 10px;
}
#link {
  border: solid 1px white;
  padding: 5px;
  width: 70%;
}
</style>