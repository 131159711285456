<template>
  <transition name="fade">
    <div
      v-if="combo >= 3"
      id="ComboDisplayer"
      :class="'level_' + Math.min(combo, 6)"
    >
      {{ combo }}
      <span> x combo ! </span>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    combo: { type: Number, requiered: true },
  },
};
</script>

<style scoped>
#ComboDisplayer {
  position: fixed;
  left: 50%;
  top: 20%;
  transition: all 0.5s;

  text-shadow: 0.5vh 0.5vh 0vh black;
}
.level_3 {
  animation: shake 3.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  font-size: 6vh;
}
.level_4 {
  font-size: 7vh;
  color: bisque;
  animation: shake 1.2s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
}
.level_5 {
  font-size: 8vh;
  color: rgb(236, 146, 110);
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
}
.level_6 {
  font-size: 10vh;
  color: rgb(192, 51, 51);
  animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
}
span{
  transform: scale(0.5);
}
</style>