<template>
  <modal @cancel="cancel">
    <div id="settings">
      <h2>Settings</h2>
      <table>
        <tr id="Music" :class="selectedSetting == 0 ? 'selected' : ''">
          <th>Music</th>
          <td>
            <div class="volumeControl">
              <div class="volumeBtn" @click="decMusicVolume()">&#9668;</div>
              <input
                type="range"
                class="volumeBar"
                min="0"
                max="100"
                :step="volumeStep"
                v-model="musicVolume"
                @input="volumeBarChange"
              />
              <div class="volumeBtn" @click="addMusicVolume()">&#9658;</div>
            </div>
          </td>
        </tr>
        <tr id="Sound" :class="selectedSetting == 1 ? 'selected' : ''">
          <th>Sound effects</th>
          <td>
            <div class="volumeControl">
              <div class="volumeBtn" @click="decSoundVolume()">&#9668;</div>
              <input
                type="range"
                class="volumeBar"
                min="0"
                max="100"
                :step="volumeStep"
                v-model="soundVolume"
                @input="volumeBarChange"
              />
              <div class="volumeBtn" @click="addSoundVolume()">&#9658;</div>
            </div>
          </td>
        </tr>
      </table>

      <button
        @click="cancel"
        id="closeBtn"
        :class="selectedSetting == 2 ? 'selected' : ''"
      >
        Close
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      musicVolume: 0,
      soundVolume: 0,

      selectedSetting: 0,
      volumeStep: 5,
    };
  },
  created() {
    document.addEventListener("keydown", this.keyAndler);
    this.musicVolume = this.$musics.getVolume();
    this.soundVolume = this.$sounds.getVolume();
  },
  methods: {
    keyAndler(k) {
      if (k.key == "ArrowDown") {
        this.selectedSetting += 1;
        if (this.selectedSetting > 2) this.selectedSetting = 0;
        this.$sounds.play("menu", "tick");
      }
      if (k.key == "ArrowUp") {
        this.selectedSetting -= 1;
        if (this.selectedSetting < 0) this.selectedSetting = 2;
        this.$sounds.play("menu", "tick");
      }
      if (k.key == "ArrowLeft") {
        if (this.selectedSetting == 0) this.decMusicVolume();
        if (this.selectedSetting == 1) this.decSoundVolume();
      }
      if (k.key == "ArrowRight") {
        if (this.selectedSetting == 0) this.addMusicVolume();
        if (this.selectedSetting == 1) this.addSoundVolume();
      }
      if (k.key == "Enter") {
        if (this.selectedSetting == 2) this.cancel();
      }
    },
    cancel() {
      this.$sounds.play("menu", "select");
      this.$emit("cancel");
    },
    addMusicVolume() {
      if (parseInt(this.musicVolume) + this.volumeStep <= 100) {
        this.musicVolume = parseInt(this.musicVolume) + this.volumeStep;
        this.$musics.setVolume(this.musicVolume);
        this.$sounds.play("menu", "tick");
      } else this.$sounds.play("menu", "error");
    },
    decMusicVolume() {
      if (parseInt(this.musicVolume) - this.volumeStep >= 0) {
        this.musicVolume = parseInt(this.musicVolume) - this.volumeStep;
        this.$musics.setVolume(this.musicVolume);
        this.$sounds.play("menu", "tick");
      } else this.$sounds.play("menu", "error");
    },
    addSoundVolume() {
      if (parseInt(this.soundVolume) + this.volumeStep <= 100) {
        this.soundVolume = parseInt(this.soundVolume) + this.volumeStep;
        this.$sounds.setVolume(this.soundVolume);
        this.$sounds.play("menu", "tick");
      } else {
        this.$sounds.play("menu", "error");
      }
    },
    decSoundVolume() {
      if (parseInt(this.soundVolume) - this.volumeStep >= 0) {
        this.soundVolume = parseInt(this.soundVolume) - this.volumeStep;
        this.$sounds.setVolume(this.soundVolume);
        this.$sounds.play("menu", "tick");
      } else {
        this.$sounds.play("menu", "error");
      }
    },
    volumeBarChange() {
      this.$sounds.play("menu", "tick");
      this.$sounds.setVolume(this.soundVolume);
      this.$musics.setVolume(this.musicVolume);
    },
  },
  watch: {},
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyAndler);
  },
};
</script>

<style scoped>
#settings {
  padding: 1vh 0 1vh 0;
  width: 100vw;
  background-color: var(--primary);
  border-top: solid 1vh black;
  border-bottom: solid 1vh black;

  justify-content: center;
  align-items: center;
}

table {
  margin-left: auto;
  margin-right: auto;
}
th {
  text-align: right;
  border: none;
}

.volumeControl {
  display: flex;
}
.volumeBtn {
  cursor: pointer;
  font-size: 5vh;
}
.volumeBtn:hover {
  transform: scale(1.3);
}
.volumeBar {
  width: 30vh;
  border: solid white 0.1vh;
  padding: 0.3vh;
}

#closeBtn {
  background-color: var(--secondary);
  width: 30vh;
}

input[type="range"] {
  -webkit-appearance: none; /*nécessaire pour Chrome */
  padding: 0; /* nécessaire pour IE */
  background: var(--primary);
  cursor: pointer;
}

.selected {
  background: rgba(255, 255, 255, 0.13);
  border: solid white 0.5vh;
}
</style>