import Vue from 'vue'
import Router from 'vue-router'
// import lazyLoading from './lazyLoading'

import Welcome from '../components/versync/Welcome'

// Singleplayer
import Singleplayer from '../components/versync/Singleplayer/Singleplayer'
import Endless from '../components/versync/Singleplayer/Endless'
import GameCreation from '../components/versync/board/GameCreation'


// Multiplayer
import Multiplayer from '../components/versync/Multiplayer/Multiplayer'
import Lobby from '../components/versync/Multiplayer/Lobby'

// Game Board
import Board from '../components/versync/board/Board'

// Demo AI
import GameCreationAi from '../components/versync/boardAi/GameCreation'
import BoardAi from '../components/versync/boardAi/Board'

// Other
import Tutorial from '../components/versync/welcome/Tutorial'
import Credits from '../components/versync/welcome/Credits'

Vue.use(Router)

export default new Router({
  routes: [
    {
      name: 'Welcome',
      meta: { title: 'Survaillant - Main menu' },
      path: '/',
      component: Welcome,
    },
    // Singleplayer
    {
      name: 'Singleplayer',
      meta: { title: 'Survaillant - Singleplayer' },
      path: '/Singleplayer',
      component: Singleplayer,
    },
    {
      name: 'Endless',
      meta: { title: 'Survaillant - Endless' },
      path: '/Endless',
      component: Endless,
    },
    {
      name: 'GameCreation',
      meta: { title: 'Survaillant - creating game' },
      path: '/GameCreation',
      component: GameCreation,
    },
    // Multiplayer
    {
      name: 'Multiplayer',
      meta: { title: 'Survaillant - Multiplayer' },
      path: '/Multiplayer',
      component: Multiplayer,
    },
    {
      name: 'Lobby',
      meta: { title: 'Survaillant - Lobby' },
      path: '/Multiplayer/Lobby/:name',
      component: Lobby,
    },

    // Game Board
    {
      name: 'Board',
      meta: { title: 'Survaillant - in game' },
      path: '/Board',
      component: Board,
    },

    // Demo AI
    {
      name: 'GameCreationAi',
      meta: { title: 'Survaillant - creating demo ai game' },
      path: '/GameCreationAi',
      component: GameCreationAi,
    },
    {
      name: 'BoardAi',
      meta: { title: 'Survaillant - AI demo' },
      path: '/Board',
      component: BoardAi,
    },

    // Other
    {
      name: 'Credits',
      meta: { title: 'Survaillant - credits' },
      path: '/Credits',
      component: Credits,
    },
    {
      name: 'Tutorial',
      meta: { title: 'Survaillant - Tutorial' },
      path: '/Tutorial',
      component: Tutorial,
    },


    // // V0
    // {
    //   name: 'AssetLoading',
    //   path: '/AssetLoading',
    //   meta: { title: 'Loading' },
    //   component: AssetLoading,
    // },
    // {
    //   name: 'GameModeSelection',
    //   meta: { title: 'Versync' },
    //   path: '/GameModeSelection',
    //   component: GameModeSelection,
    // },
  ],
})
