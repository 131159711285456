<template>
  <div id="partGroup">
    <div class="particule" v-for="i in nbparticule" :key="i"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nbparticule: 100,
    };
  },
};
</script>

<style lang="scss" scoped>
/* Particules */
#partGroup {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
}
@for $i from 1 through 100 {
  @keyframes particule-animation-#{$i} {
    100% {
      // transform: translate(
      //   (random(100) * 1vw),
      //   (random(100) * 1vh),
      // );
      transform: translate(100vw, (random(100)) - 25 * 1vh);
      opacity: 0.2;
    }
  }

  .particule:nth-child(#{$i}) {
    position: absolute;
    $size: random(10) + 2;
    animation: particule-animation-#{$i}  $size * 1s infinite ease-out;
    // opacity: random(100) / 100;
    height: calc($size/15) *1vh;
    width: calc($size/10)  *1vh;

    animation-delay: -$i * 0.1s;

    transform: translate(0vw, random(75)* 1vh);
    top: 25vh;
    border-radius: 10vh;

    $col:random(205);
    background: rgba($col + 50, 0, 0);
  }
}
</style>