<template>
  <div id="Singleplayer">
    <h1>Select a gamemode</h1>
    <Menu
      id="menu"
      :choices="gameModes"
      v-on:select="select"
      v-on:back="back"
    />
  </div>
</template>

<script>
export default {
  name: "Singleplayer",
  data() {
    return {
      gameModes: [
        { name: "Endless", available: true },
        { name: "Quest", available: false },
      ],
    };
  },

  methods: {
    back() {
      this.$router.push("/");
    },
    select(gameMode) {
      this.$router.push({
        name: gameMode,
        params: {},
      });
    },
  },
};
</script>

<style scoped>
#Singleplayer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
h1 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 5vh;
}
#menu {
  flex: 1;
}
</style>