<template>
  <div id="scoreList">
    <span>
      <button class="red" @click="$emit('exit')">Close</button>
    </span>

    <!-- Pannel selection : -->
    <span>
      <!-- Solo -->
      <button v-if="selectedScorePannel == 1" class="blue">Solo</button>
      <button v-else @click="selectedScorePannel = 1">Solo</button>

      <!-- 2 players -->
      <button v-if="selectedScorePannel == 2" class="blue">2 Players</button>
      <button v-else @click="selectedScorePannel = 2">2 Players</button>

      <!-- 3 players -->
      <button v-if="selectedScorePannel == 3" class="blue">3 Players</button>
      <button v-else @click="selectedScorePannel = 3">3 Players</button>

      <!-- 4 players -->
      <button v-if="selectedScorePannel == 4" class="blue">4 Players</button>
      <button v-else @click="selectedScorePannel = 4">4 Players</button>
    </span>

    <!-- Score display : -->
    <!-- Solo -->
    <div v-if="selectedScorePannel == 1">
      <div v-if="scores.solo.length == 1" id="noScore">No score yet</div>
      <div v-else id="scores">
        <score
          class="score"
          v-for="(score, i) in scores.solo"
          :score="score"
          :key="i"
        />
      </div>
    </div>

    <!-- 2, 3 and 4 players -->
    <div v-if="selectedScorePannel > 1 && scores.coop[selectedScorePannel]">
      <div v-if="scores.coop[selectedScorePannel].length == 0" id="noScore">
        No score yet
      </div>
      <div v-else id="scores">
        <score
          class="score"
          v-for="(score, i) in scores.coop[selectedScorePannel]"
          :score="score"
          :key="i"
        />
      </div>
    </div>
  </div>
</template>

<script>
import score from "./Score";
export default {
  components: { score },
  data() {
    return {
      selectedScorePannel: 1,
    };
  },
  props: {
    scores: { type: Object, required: true },
    defaultScorePannel: { type: Number, default: 1 },
  },
  created() {
    document.addEventListener("keydown", this.keyAndler);
    this.selectedScorePannel = this.defaultScorePannel;
  },
  methods: {
    keyAndler(k) {
      if (k.key == "Escape") {
        this.back();
      }
    },
    back() {
      this.$sounds.play("menu", "select");
      this.$emit("exit");
    },
  },
  computed: {},
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyAndler);
  },
};
</script>

<style scoped>
#noScore {
  color: red;
  font-weight: bold;
}
#scoreList {
  display: flex;
  flex-direction: column;
  text-align: left;
  background: var(--primary);
  padding: 2vh;
  overflow-y: auto;
}

/* Podium colors */
.score {
  border-radius: 1vh;
}
.score:nth-child(1) {
  background-color: rgba(255, 217, 0, 0.164);
}
.score:nth-child(2) {
  background-color: rgba(233, 233, 233, 0.164);
}
.score:nth-child(3) {
  background-color: rgba(167, 75, 7, 0.1645);
  margin-bottom: 2vh;
}

/* Values */
.time {
  font-size: 2vh;
  color: rgb(68, 68, 68);
}

/* Accounts */

.accounts {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.account {
  align-items: center;
  display: flex;
}
.guest {
  font-size: 1.5vh;
  color: rgb(68, 68, 68);
}
</style>