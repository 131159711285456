const { Howl } = require('howler');

import config from '../../config'
const apiURL = config.API_URL

const soundToLoad = {
  "menu":
    ["select", "tick", "error"],
  "game":
    ["arrow", "damage", "explosion", "playerStep", "newItem", "swordHit", "monsterDeath", "chestOpen", "playerItemPlacement"]
}

var sounds = []
var volume = localStorage["soundVolume"] || 0.5

function loadSounds() {
  for (const cat in soundToLoad) {
    sounds[cat] = {}
    for (const sound of soundToLoad[cat]) {
      sounds[cat][sound] = newSound(cat, sound)
    }
  }
}

function newSound(cat, sound) {
  return new Howl({
    src: [apiURL + "sounds/" + cat + "/" + sound + ".wav"],
    volume: volume
  })
}

loadSounds()

export default {
  play(cat, sound) {
    if (sounds[cat][sound]) sounds[cat][sound].play()
    else console.warn("Sound " + sound + " not found");
  },
  setVolume(vol) {
    volume = vol / 100
    for (const cat in soundToLoad) {
      for (const sound of soundToLoad[cat]) {
        sounds[cat][sound].volume(volume);
      }
    }
    localStorage["soundVolume"] = volume;
  },
  getVolume() {
    return volume * 100
  },
}