<template>
  <modal @cancel="cancel">
    <div id="login">
      <form v-on:submformit.prevent onsubmit="return false">
        <h2>Login</h2>
        <div class="rowList">
          <div class="row">
            <div class="rowTitle">Username :</div>
            <input
              id="usernameInput"
              type="text"
              v-model="loginUsername"
              name="username"
              required
            />
          </div>
          <div class="row">
            <div class="rowTitle">Password :</div>
            <input
              type="password"
              v-model="loginPassword"
              name="password"
              required
            />
          </div>
        </div>
        <button @click="login" type="submit" id="loginBtn">Login</button>
      </form>
      <button @click="cancel" id="cancelBtn">Cancel</button>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      // login form
      loginUsername: "",
      loginPassword: "",
    };
  },
  mounted() {
    // document.getElementById("usernameInput").focus()
  },
  methods: {
    login() {
      if (this.loginUsername.length < 2) {
        this.$store.commit("sendMessage", {
          type: "error",
          text: "The username is requiered",
        });
        return;
      }
      if (this.loginPassword.length < 2) {
        this.$store.commit("sendMessage", {
          type: "error",
          text: "The password is requiered",
        });
        return;
      }
      this.$backendDialog
        .login({
          username: this.loginUsername,
          password: this.loginPassword,
        })
        .then((c) => {
          // Login success
          this.$store.commit("setAccount", c.account);
          this.$store.commit("sendMessage", {
            type: "success",
            text: "Welcome back !",
          });
          this.$emit("login");
        })
        .catch((e) => {
          if (e.response && e.response.status == 402) {
            this.$store.commit("sendMessage", {
              type: "error",
              text: "I didn't found you\nAre you registered ?",
            });
          } else if (e.response && e.response.status == 403) {
            this.$store.commit("sendMessage", {
              type: "error",
              text: "You are already logged on an other device",
            });
          } else {
            console.error(e);

            this.$store.commit("sendMessage", {
              type: "error",
              text: "Error while login",
            });
          }
        });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
#login {
  padding: 1vh 0 1vh 0;
  width: 100vw;
  background-color: var(--primary);
  border-top: solid 1vh black;
  border-bottom: solid 1vh black;
}

#loginBtn {
  background-color: var(--secondary);
  width: 30vh;
}
</style>