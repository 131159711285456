import Modal from './Modal.vue';
import Icon from './Icon.vue';
import MapMenu from './MapMenu.vue';
import Menu from './Menu.vue';

export default [
  Modal,
  Icon,
  MapMenu,
  Menu
];

// /!\ Comp name is requiered