import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/router'
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';
import config from '../config'

const socket = io(config.API_URL);

Vue.use(VueSocketIOExt, socket);
Vue.config.productionTip = false

// Commun Componants
import commonComponents from './components/commun';
commonComponents.forEach(component => {
  Vue.component(component.name, component);
});

// Commun services
import backendDialog from './services/backendDialog'
import services from './services/services'
import sounds from './services/sounds'
import musics from './services/musics'
Vue.prototype.$backendDialog = backendDialog
Vue.prototype.$services = services
Vue.prototype.$sounds = sounds
Vue.prototype.$musics = musics

// Api
Vue.prototype.$API_URL = config.API_URL

// New vue
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')