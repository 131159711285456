<template>
  <div id="Welcome">
    <!-- Modals  -->
    <login v-if="loginIn" @cancel="loginIn = false" @login="loginIn = false" />
    <Register
      v-if="registerIn"
      @cancel="registerIn = false"
      @registered="registerIn = false"
    />
    <Settings
      v-if="settings"
      @cancel="settings = false"
      @registered="settings = false"
    />
    <!-- Body  -->
    <div id="top">
      <div id="title">
        <h1>Surva llant</h1>
      </div>
      <div id="languageSelection">
        <div id="title">
          <h1>Survaillant</h1>
        </div>
        <!-- Language
        <div id="language">&#9668; English &#9658;</div> -->
      </div>
    </div>
    <div id="mid">
      <div id="red"></div>
      <div id="black"></div>
    </div>
    <div id="bot">
      <div id="left">
        <div id="knight">
          <img src="../../assets/images/ui/knight.png" alt />
        </div>
      </div>
      <div id="menu">
        <div v-for="(menu, i) in choices" v-bind:key="menu.name">
          <div
            :id="menu.name"
            :class="
              'choice' +
              (menuSelection == i ? ' selected' : '') +
              (!menu.available ? ' unavailable' : ' available')
            "
            @mouseover="over(i)"
            @click="selection"
          >
            <div class="imgBox">
              <transition name="pick">
                <img
                  class="arrow"
                  v-show="menuSelection == i"
                  src="../../assets/images/ui/arrow_sword.png"
                  alt
                />
              </transition>
            </div>
            <div class="title shine">{{ menu.name }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Particules -->
    <Particules />
  </div>
</template>

<script>
import login from "./welcome/Login";
import Register from "./welcome/Register";
import Settings from "./welcome/settings/Settings";
import Particules from "./welcome/Particules";

export default {
  name: "Welcome",
  components: {
    login,
    Register,
    Settings,
    Particules,
  },
  data() {
    return {
      unLoggedList: [
        { name: "Login", available: true, color: "#990000" },
        { name: "Register", available: true, color: "#990000" },
      ],
      loggedList: [{ name: "Disconnect", available: true, color: "#990000" }],
      menuList: [
        { name: "Singleplayer", available: true },
        { name: "Multiplayer", available: true },
        { name: "Tutorial", available: true },
        { name: "Credits", available: true },
        { name: "Settings", available: true },
      ],
      choices: [],
      menuSelection: 2,

      loginIn: false,
      registerIn: false,
      settings: false,
    };
  },
  created() {
    this.updateMenu();
    document.addEventListener("keydown", this.keyAndler);
  },
  methods: {
    keyAndler(k) {
      if (k.key == "Escape") {
        this.loginIn = false;
        this.registerIn = false;
        this.settings = false;
        return;
      }
      if (!this.loginIn && !this.registerIn && !this.settings) {
        if (k.key == "ArrowDown") {
          this.menuSelection += 1;
          if (this.menuSelection >= this.choices.length) this.menuSelection = 0;
          this.$sounds.play("menu", "tick");
        }
        if (k.key == "ArrowUp") {
          this.menuSelection -= 1;
          if (this.menuSelection < 0)
            this.menuSelection = this.choices.length - 1;
          this.$sounds.play("menu", "tick");
        }
        if (k.key == "Enter") {
          this.selection();
        }
      }
    },
    selection() {
      if (this.choices[this.menuSelection].available) {
        this.$sounds.play("menu", "select");

        if (this.choices[this.menuSelection].name == "Login") {
          this.loginIn = true;
          return;
        }
        if (this.choices[this.menuSelection].name == "Register") {
          this.registerIn = true;
          return;
        }
        if (this.choices[this.menuSelection].name == "Disconnect") {
          this.$backendDialog.disconnect().then(() => {
            this.$store.commit("removeAccount");
          });
          return;
        }
        if (this.choices[this.menuSelection].name == "Settings") {
          this.settings = true;
          return;
        }

        this.$router.push({
          name: this.choices[this.menuSelection].name,
          params: {},
        });
      } else {
        this.$sounds.play("menu", "error");
      }
    },
    over(index) {
      this.menuSelection = index;
      this.$sounds.play("menu", "tick");
    },

    updateMenu() {
      if (this.loggedIn) {
        this.menuSelection = 1;
        this.choices = [...this.menuList, ...this.loggedList];
      } else {
        this.menuSelection = 2;
        this.choices = [...this.unLoggedList, ...this.menuList];
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.account !== null;
    },
  },
  watch: {
    loggedIn() {
      this.updateMenu();
    },
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyAndler);
  },
};
</script>

<style lang="scss" scoped>
#Welcome {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
}

/* TOP  */
#top {
  padding-top: 10vh;
  display: flex;
}
#top #title {
  flex: 1;
}
#top #title h1 {
  padding-left: 8vh;
  text-align: left;
  font-size: 17vh;
  letter-spacing: 0.6vh;
  color: #990000;
  text-shadow: 0.5vh 0.5vh 0vh black;
  font-weight: normal;
  font-style: normal;
}
#top #title ::before {
  width: 3vh;
}
#top #languageSelection {
  flex: 0.3;
  text-align: left;
}
#top #languageSelection #language {
  color: #990000;
  font-size: 6vh;
}
#top #languageSelection #title {
  display: none;
}
#top #languageSelection #title h1 {
  font-size: 10vh;
}

/* MID */
#mid {
  box-shadow: 0vh 0vh 1vh 0vh rgba(0, 0, 0, 0.5);
  margin-bottom: 5vh;
}
#mid #red {
  background-color: #990000;
  height: 6vh;
}
#mid #black {
  background-color: black;
  height: 3vh;
}

/* BOT */
#bot {
  flex: 1;
  display: flex;
}
#bot #left {
  flex: 1;
  text-align: left;
}
#bot #left #knight img {
  position: relative;
  top: -30vh;
  left: 12.4vh;
  height: 90vh;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

/* menu */
#bot #menu {
  flex: 0.5;
  display: flex;
  flex-direction: column;
}
#bot #menu .choice {
  display: flex;
  cursor: pointer;
}
#bot #menu .choice .imgBox {
  width: 7vh;
  display: flex;
  align-items: center;
}
#bot #menu .choice .title {
  font-size: 5vh;
  transition: padding-left 0.1s;
  transform-origin: left;
  /* color: rgba(255, 255, 255, 0.7); */
}
#bot #menu .choice.available.selected .title {
  padding-left: 0.5vh;
  transform: scale(1.1);
}
#bot #menu .choice.unavailable .title {
  cursor: not-allowed;
  filter: brightness(35%);
}

#bot #menu #Login .title {
  color: #990000;
  font-size: 3vh;
  padding: 0.5vh;
}
#bot #menu #Register .title {
  color: #990000;
  font-size: 3vh;
  padding: 0.5vh;
}
#bot #menu #Disconnect .title {
  color: #990000;
  font-size: 3vh;
  margin: 0.5vh;
}

/* arrow Animations */
.arrow {
  position: relative;
  width: 6vh;
  animation: 272.5ms ease-in-out 0s infinite alternate spinning;
}
@keyframes spinning {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0.1);
  }
}

.pick-enter-active {
  transition: all 0.1s ease-out;
}
.pick-enter {
  left: -15vh;
  opacity: 0;
}
.pick-enter-to {
  left: 0vh;
  opacity: 1;
}
.pick-leave-active {
  transition: all 0.05s ease-in;
}
.pick-leave {
  opacity: 1;
}
.pick-leave-to {
  opacity: 0;
}

@media (max-width: 1000px) and (orientation: landscape) {
  #bot #menu .choice .title {
    font-size: 8vh;
  }

  #bot #menu #Login .title {
    font-size: 6vh;
  }
  #bot #menu #Register .title {
    font-size: 6vh;
  }
  #bot #menu .choice.unavailable .title {
    font-size: 5vh;
  }
}

@media screen and (orientation: portrait) {
  #bot #left #knight img {
    display: none;
  }
  #top #title {
    display: none;
  }
  #top #languageSelection {
    flex: 100;
    text-align: center;
  }
  #top #languageSelection #title {
    display: inline;
  }
  #top #languageSelection #title h1 {
    font-size: 18vw;
    padding: 0;
    text-align: center;
  }
  #bot #menu {
    align-items: center;
    flex: 100;
  }
  #bot #menu .choice .imgBox {
    display: none;
  }
}
</style>