<template>
  <div id="modal">
    <div id="pannel">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {};
  }
};
</script>

<style >
#modal {
  z-index: 10;
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0%;
  top: 0%;
  background-color: rgba(0, 0, 0, 0.5);

  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#modal #pannel {
  max-height: 90vh;
  overflow: auto;
}
</style>