<template>
  <div id="loading">
    <transition-group id="list" name="list" tag="p">
      <div class="request" v-for="req in requestList" :key="req.code">
        <div class="name">{{req.name}}</div>
        <div class="loader" />
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data: () => {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    requestList() {
      return this.$store.state.requestList;
    }
  },
  watch: {
    requestList() {}
  }
};
</script>

<style scoped>
#loading {
  position: fixed;
  bottom: 0;
  right: 0;

  overflow: hidden;
}
#list {
  margin-bottom: 0vh;
  margin-bottom: 0vh;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.request {
  display: flex;
  align-items: center;

  margin-right: 2vh;
  margin-bottom: 1vh;
  padding: 0.5vh;
  color: #fff;
  background-color: var(--info);
  border-radius: 1.5vh;
  overflow: hidden;
}

.name {
  padding-right: 1vh;
}

.loader {
  border: 0.2vh solid #f3f3f3; /* Light grey */
  border-top: 0.2vh solid #3498db; /* Blue */
  border-right: 0.2vh solid #3498db; /* Blue */
  border-radius: 100%;
  width: 1.5vh;
  height: 1.5vh;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.list-enter-active,
.list-leave-active {
  transition: opacity 3s, transform 3s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30vh);
}
</style>