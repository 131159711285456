<template>
  <div id="header">
    <div id="left">
      <h1 @click="mainMenu" class="title">
        <u>Survaillant</u>
      </h1>
      <div id="online" title="Online players" v-if="onlinePlayers">
        {{ onlinePlayers }}
        <div id="greenCircle"></div>
      </div>
    </div>
    <div id="profile" v-if="$store.state.account">
      <div id="accountName">{{ $store.state.account.name }}</div>
      <div id="versusRank" title="Victory / Game played">
        <div id="wins">{{ versusWin }}</div>
        <div>/</div>
        <div id="nbGames">{{ versusGames }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      onlinePlayers: 0,
      versusGames: 0,
      versusWin: 0,
    };
  },
  mounted() {},
  created() {
    if (
      this.$store.state.account == null ||
      this.$store.state.account == undefined
    ) {
      this.disconect();
      return;
    }

    this.$backendDialog
      .getNbOnline()
      .then(({ nbOnline, versusGames, versusWin }) => {
        this.onlinePlayers = nbOnline;
        this.versusGames = versusGames;
        this.versusWin = versusWin;
      });
  },
  methods: {
    disconect() {
      // this.$store.commit("removeAccount");
      // this.$router.push("/");
    },
    mainMenu() {
      this.$router.push("/");
    },
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    clientId() {
      return this.$store.state.clientId;
    },
  },
  watch: {
    userId() {},
    clientId() {},
  },
  sockets: {
    onlinePLayerUpdate: function (nb) {
      this.onlinePlayers = nb;
    },
  },
};
</script>

<style scoped>
#header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: var(--blank);
  padding: 1vh;
}
#left {
  display: flex;
}
#online {
  display: flex;
  align-items: center;
  margin-left: 2vw;
  padding-left: 0.5vw;
  /* color: rgb(70, 70, 70); */
  border: solid rgb(255, 255, 255) 0.2vh;
  border-radius: 1.1vh;
}
#greenCircle {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  height: 1vh;
  width: 1vh;
  background-color: var(--success);
  border-radius: 100%;
}
#profile {
  display: flex;
  align-items: center;
}

#accountName {
  margin-left: 2vw;
}

/* Versus rank */
#versusRank {
  border: solid rgb(255, 255, 255) 0.2vh;
  border-radius: 1.1vh;
  margin-left: 1vw;
  margin-right: 1vw;
  display: flex;
}
#wins {
  font-weight: bold;
  color: rgb(0, 206, 0);
}
#nbGames {
  /* color: rgb(70, 70, 70); */
}

#pick {
  background-color: gray;
  height: 5vh;
  width: 5vh;
  margin-right: 1vw;
  border-radius: 100%;
}
.title {
  cursor: pointer;
  user-select: none;
  margin-left: 2vw;
  font-size: 3vh;
}
.title:hover {
  transform: scale(1.05);
}
@media (max-width: 600px) {
  .title {
    font-size: 2vh;
    margin-left: 0vw;
  }
  .red {
    font-size: 2vh;
  }
}
</style>