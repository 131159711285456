var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"About"}},[_c('div',{attrs:{"id":"cont"}},[_c('button',{staticClass:"red",attrs:{"id":"back"},on:{"click":_vm.back}},[_vm._v("⇦ Back")]),_c('h1',[_vm._v("About")]),_c('p',[_vm._v("Survaillant is my WIP Web multiplayer game")]),_c('img',{attrs:{"src":require("../../../assets/images/ui/tom.png"),"alt":""}}),_c('br'),_c('br'),_c('h2',[_vm._v("Credits")]),_vm._m(0),_c('br'),_c('h2',[_vm._v("Assets")]),_vm._m(1),_c('br'),_c('h2',[_vm._v("Roadmad")]),_c('br'),_c('h3',[_vm._v("V1.0.1")]),_vm._m(2),_c('br'),_c('h3',[_vm._v("V2.0.0")]),_vm._m(3),_c('h3',[_vm._v("V3.0.0")]),_vm._m(4),_c('br'),_c('h3',[_vm._v("V4.0.0")]),_c('li',[_vm._v("Playable Classes implementation :")]),_vm._m(5),_vm._m(6),_c('h3',[_vm._v("V4.2.0")]),_vm._v(" ??? "),_c('br'),_c('br'),_c('h3',[_vm._v("V6.9.0")]),_vm._v(" Profit "),_c('br'),_c('br'),_c('h2',[_vm._v("Thanks")]),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Humongous thanks to "),_c('b',[_vm._v("Elio")]),_vm._v(" for all the custom made Musics")]),_c('li',[_vm._v(" Enromous thanks to "),_c('a',{attrs:{"href":"https://graphiste.com/MaureenBx"}},[_vm._v("MaureenBx")]),_vm._v(" for the GUI and GUX help ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Tileset : "),_c('a',{attrs:{"href":"https://0x72.itch.io/dungeontileset-ii"}},[_vm._v("Dungeon Tileset II")])]),_c('li',[_vm._v(" Arrow animation : "),_c('a',{attrs:{"href":"https://www.fiverr.com/diegorago138?source=order_page_summary_seller_link"}},[_vm._v("diegorago138")])]),_c('li',[_vm._v(" Sound effects : "),_c('a',{attrs:{"href":"https://opengameart.org/content/512-sound-effects-8-bit-style"}},[_vm._v("8 bit sound effects")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Create the tutorial")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Create all gamemodes")]),_c('li',[_vm._v("New skills :")]),_c('ul',[_c('li',[_vm._v("Dash (Jump hover 2 tiles)")]),_c('li',[_vm._v("Invoc (Spawn a monster on your side)")]),_c('li',[_vm._v("Dwarf (more dynamites)")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Complete custom tileset creation by expert")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Elf (more arrows)")]),_c('li',[_vm._v("Knight (more Bombs)")]),_c('li',[_vm._v("Dwarf (more dynamites)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Mobile app creation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Testers : Léandre, Nathan & Maxime")])])
}]

export { render, staticRenderFns }