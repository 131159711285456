<template>
  <div id="Multiplayer">
    <button class="red" @click="back">&#9668; Back</button>

    <!-- Create game -->
    <form class="pannel" id="createLobby" v-on:submformit.prevent  onsubmit="return false">
      Create a multiplayer lobby :
      <input
        type="text"
        placeholder="Game name"
        pattern="[a-z A-Z 1-9]{1,}"
        v-model="name"
        required
      />
      <div>
        <input type="radio" v-model="isPrivate" v-bind:value="'yes'" />Private
        <input type="radio" v-model="isPrivate" v-bind:value="'no'" />Public
        <button class="red" @click="createLobby" type="submit">Create</button>
      </div>
    </form>

    <!-- Join game -->
    <div class="pannel" id="joinLobby">
      Join a game :
      <div>
        <input type="text" placeholder="Game name" v-model="joinLobbyName" />
        <button class="red" @click="joinLobby">Join</button>
      </div>
    </div>

    <!-- created games -->
    <div class="pannel" id="createdLobby">
      Public games :
      <div
        class="lobby"
        v-for="(lobby, i) in lobbyList"
        :key="i"
        @click="joinLobbyByName(lobby.name)"
      >
        <span>{{ lobby.name }}</span>
        <span>Selected map : {{ lobby.selectedMapId }}</span>
        <span>{{ lobby.lobbyPlayers.length }} / 4 Players</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mame: "Multiplayer",
  data() {
    return {
      name: "My game",
      isPrivate: "no",
      joinLobbyName: "",
      lobbyList: [],
    };
  },
  mounted() {
    // Load lobby
    this.$backendDialog.getLobby().then((resp) => {
      this.lobbyList = resp.data;
    });
  },
  methods: {
    back() {
      this.$router.push("/");
    },
    createLobby() {
      if (new RegExp("[a-z A-Z 1-9]{1,}").test(this.name))
        this.$router.push({
          path: "/Multiplayer/Lobby/" + this.name,
          query: { isPrivate: this.isPrivate === "yes" },
        });
      else
        this.$store.commit("sendMessage", {
          type: "info",
          text: "Bad game name",
        });
    },
    joinLobby() {
      this.$router.push("/Multiplayer/Lobby/" + this.joinLobbyName);
    },
    joinLobbyByName(name) {
      this.$router.push("/Multiplayer/Lobby/" + name);
    },
  },
  sockets: {
    lobbyListUpdate: function (lobbyList) {
      this.lobbyList = lobbyList;
    },
  },
};
</script>

<style scoped>
#createdLobby {
  display: flex;
  border: solid white 1px;
  align-items: center;
}
.lobby {
  display: flex;
  justify-content: space-around;
  width: 90%;

  margin: 10px;
  padding: 10px;
  border: solid white 1px;

  cursor: pointer;
}
.lobby:hover {
  background: rgb(100, 100, 100);
}
</style>