<template>
  <svg class="icon" :style="'width:' + width + ';height:' + height" :viewBox="viewBox">
    <path :d="path" />
  </svg>
</template>

<script>
import icons from "../../assets/svg/svg.json";

export default {
  name: "Icon",
  props: {
    name: String,
    viewBox: { type: String, default: "0 0 24 24" },
    width: { type: String, default: "2vw" },
    height: { type: String, default: "2vw" },
  },
  computed: {
    path() {
      return icons[this.name];
    }
  }
};
</script>