<template>
  <div id="About">
    <div id="cont">
      <button class="red" id="back" @click="back">&#8678; Back</button>

      <h1>About</h1>
      <p>Survaillant is my WIP Web multiplayer game</p>
      <img src="../../../assets/images/ui/tom.png" alt />
      <br />
      <br />
      <h2>Credits</h2>
      <ul>
        <li>Humongous thanks to <b>Elio</b> for all the custom made Musics</li>
        <li>
          Enromous thanks to
          <a href="https://graphiste.com/MaureenBx">MaureenBx</a> for the GUI
          and GUX help
        </li>
      </ul>
      <br />

      <h2>Assets</h2>
      <ul>
        <li>
          Tileset :
          <a href="https://0x72.itch.io/dungeontileset-ii"
            >Dungeon Tileset II</a
          >
        </li>
        <li>
          Arrow animation :
          <a
            href="https://www.fiverr.com/diegorago138?source=order_page_summary_seller_link"
            >diegorago138</a
          >
        </li>
        <li>
          Sound effects :
          <a
            href="https://opengameart.org/content/512-sound-effects-8-bit-style"
            >8 bit sound effects</a
          >
        </li>
      </ul>

      <br />
      <h2>Roadmad</h2>
      <br />
      <h3>V1.0.1</h3>
      <ul>
        <li>Create the tutorial</li>
      </ul>
      <br />
      <h3>V2.0.0</h3>
      <ul>
        <li>Create all gamemodes</li>
        <li>New skills :</li>
        <ul>
          <li>Dash (Jump hover 2 tiles)</li>
          <li>Invoc (Spawn a monster on your side)</li>
          <li>Dwarf (more dynamites)</li>
        </ul>
      </ul>
      <h3>V3.0.0</h3>
      <ul>
        <li>Complete custom tileset creation by expert</li>
      </ul>
      <br />
      <h3>V4.0.0</h3>
      <li>Playable Classes implementation :</li>
      <ul>
        <li>Elf (more arrows)</li>
        <li>Knight (more Bombs)</li>
        <li>Dwarf (more dynamites)</li>
      </ul>
      <ul>
        <li>Mobile app creation</li>
      </ul>
      <h3>V4.2.0</h3>
      ???
      <br />
      <br />
      <h3>V6.9.0</h3>
      Profit
      <br />
      <br />
      <h2>Thanks</h2>
      <ul>
        <li>Testers : Léandre, Nathan & Maxime</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    document.addEventListener("keydown", this.keyAndler);
  },
  methods: {
    keyAndler(k) {
      if (k.key == "Escape") {
        this.back();
      }
    },
    back() {
      this.$sounds.play("menu", "select");
      this.$router.push({ name: "Welcome" });
    },
  },
  computed: {},
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyAndler);
  },
};
</script>

<style scoped>
#About {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
#cont {
  background-color: rgb(160, 160, 160);
  color: black;
  padding: 2vh;
  overflow-y: scroll;
  width: 70vw;
}
ul {
  padding-bottom: 2vh;
}
h2 {
  color: white;
  background-color: rgb(78, 78, 78);
}
h3 {
  text-decoration: underline;
}

#back {
  position: fixed;
  left: 0;
}

@media (max-width: 1400px) {
  #cont {
    width: 100vw;
  }
}
</style>