<template>
  <div id="mapSelection">
    Select a Multiplayer map

    <button @click="$emit('cancel')" class="red">Cancel</button>
    <MapMenu
      id="menu"
      :maps="dungeons"
      :defaultScorePannel="defaultScorePannel"
      v-on:select="
        (mId) => {
          $emit('select', mId);
        }
      "
      v-on:back="$emit('cancel')"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      dungeons: [],
    };
  },
  props: {
    defaultScorePannel: { type: Number, default: 1 },
  },
  created() {
    // Load dungeons
    this.$backendDialog.getDungeonMaps().then((dungeons) => {
      this.dungeons = dungeons;
      this.loading = false;
    });
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
#mapSelection {
  padding: 1vh 0 1vh 0;
  width: 100vw;
  background-color: var(--primary);
  border-top: solid 1vh black;
  border-bottom: solid 1vh black;
}
</style>