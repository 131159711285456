<template>
  <li id="LobbyPlayer">
    <u id="name">{{ name }}</u>
    <span id="team" :class="'number_' + lobbyPlayer.team">
      Team {{ lobbyPlayer.team }}
    </span>
    <span id="isClient" v-if="isClient">You</span>
    <span id="isOwner" v-if="isOwner">Owner</span>
    <span id="isNotReady" v-if="!lobbyPlayer.ready">Not ready</span>
    <span v-if="isClient"
      >Change team :
      <select v-model="team">
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
      </select>
    </span>
  </li>
</template>

<script>
export default {
  data() {
    return {
      team: 1,
    };
  },
  props: {
    lobbyName: { type: String, requiered: true },
    lobbyOwnerPublicId: { type: String, requiered: false },
    lobbyPlayer: { type: Object, requiered: true },
  },
  computed: {
    name() {
      if (this.lobbyPlayer.client.account)
        return this.lobbyPlayer.client.account.name;
      else return this.lobbyPlayer.guestName;
    },
    isClient() {
      return this.lobbyPlayer.client.id !== undefined;
    },
    isOwner() {
      return this.lobbyPlayer.publicId === this.lobbyOwnerPublicId;
    },
  },
  watch: {
    team() {
      this.$backendDialog
        .changeTeam(this.lobbyName, this.team)
        .catch(() => this.$router.push("/Multiplayer"));
    },
  },
};
</script>

<style scoped>
#team {
  border: solid 3px;
  border-radius: 10px;
  padding: 0 10px 0 10px;
  margin: 0 30px 0 30px;
}
#team.number_1 {
  border-color: rgb(0, 191, 255);
}
#team.number_2 {
  border-color: rgb(255, 81, 0);
}
#team.number_3 {
  border-color: rgb(3, 171, 14);
}
#team.number_4 {
  border-color: rgb(218, 41, 245);
}
#isClient {
  border: solid 2px orange;
  border-radius: 10px;
  padding: 0 10px 0 10px;
  margin: 0 30px 0 30px;
}
#isOwner {
  border: solid 2px gold;
  border-radius: 10px;
  padding: 0 10px 0 10px;
  margin: 0 30px 0 30px;
}
#isNotReady {
  border: solid 2px red;
  border-radius: 10px;
  padding: 0 10px 0 10px;
  margin: 0 30px 0 30px;
}
</style>