import Vue from 'vue'
import Vuex from 'vuex'

/* eslint-disable0 */

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    msgList: [],
    requestList: [],

    // User info
    clientId: null,
    account: null,

    // Player info
    gameId: null,
    gameType: null,
    playerId: null,
    playerTeam: null,

    // Menu Info
    mode: null
  },
  mutations: {
    // Messages
    sendMessage(state, msg) {
      msg.id = Math.random()
      state.msgList.push(msg)
      setTimeout(() => {
        state.msgList = state.msgList.filter(m => m.id != msg.id)
      }, 5000)
    },
    removeMessage(state, msg) {
      state.msgList = state.msgList.filter(m => m != msg)
    },

    // Requests
    startRequest(state, { name, code }) {
      state.requestList.push({
        name,
        code
      })
    },
    endRequest(state, code) {
      state.requestList = state.requestList.filter(r => r.code !== code)
    },

    // Menu
    setGameMode(state, mode) {
      state.mode = mode
    },
    setGameType(state, gameType) {
      state.gameType = gameType
    },

    // Games
    setClientId(state, id) {
      state.clientId = id
    },
    setAccount(state, a) {
      state.account = a

      if (a.token) {
        localStorage['userToken'] = a.token
      }
    },
    updateScores(state, scores) {
      state.account.scores = scores
    },
    removeAccount(state) {
      state.account = null
      state.player = null
      localStorage['userToken'] = null
    },
    setGameId(state, id) {
      state.gameId = id
    },
    removeGameId(state) {
      state.gameId = null
    },
    setPlayerId(state, id) {
      state.playerId = id
    },
    setPlayerTeam(state, team) {
      state.playerTeam = team
    },
    // ==== Party ====
  }
});