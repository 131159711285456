<template>
  <div id="Messenger">
    <transition-group id="list" name="list" tag="p">
    <div
      :class="'message ' + msg.type"
      v-for="msg in msgList"
      :key="msg.id"
      @click="removeMsg(msg)"
    >
      <h2 class="typeName" v-if="msg.type !== 'blank'">{{msg.type}}</h2>
      <pre class="text">{{msg.text}}</pre>
    </div>
    </transition-group>
  </div>
</template>

<script>

export default {
  name: "Messenger",
  data: () => {
    return {};
  },
  methods: {
    removeMsg: function(msg) {
      this.$store.commit("removeMessage", msg);
    }
  },
  computed: {
    msgList() {
      return this.$store.state.msgList;
    }
  },
  watch: {
    msgList() {}
  }
};
</script>

<style scoped>
#Messenger {
  z-index: 3;
  position: fixed;
  bottom: 0%;
  max-width: 100vw;
  overflow: hidden;
}
#list {
  margin-bottom: 0vh;
  margin-left: 1vh;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}

.message {
  height: 7vh;
  cursor: pointer;

  margin-bottom: 1vh;
  padding: 2vh;
  padding-left: 2vh;

  border-left: solid 1vh;
  color: #fff;
  text-align: left;
  border-radius: 1vh;
  transition: filter 0.1s;
  overflow: hidden;
}

.message:hover {
  filter: brightness(90%);
}

.message.info {
  background-color: var(--info);
  border-color: var(--infoDark);
}
.message.blank {
  background-color: var(--blank);
  border-color: var(--blankDark);
}
.message.success {
  background-color: var(--success);
  border-color: var(--successDark);
}
.message.warning {
  background-color: var(--warning);
  border-color: var(--warningDark);
}
.message.error {
  background-color: var(--danger);
  border-color: var(--dangerDark);
}

.text{
  font-size: 2vh;
}
.typeName{
  font-size: 3vh;
}
/* Transition */
.list-enter-active,
.list-leave-active {
  background-color: red;
  transition: opacity 1s, height 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  height: 0vh;
}
</style>