<template>
  <div id="MapMenu">
    <div id="maps">
      <div :id="map.name" v-for="(map, i) in maps" :key="i">
        <Map
          :map="map"
          :selected="i == selected"
          v-on:play="play(i)"
          v-on:demoAi="demoAi(i)"
          :defaultScorePannel="defaultScorePannel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Map from "../versync/map/Map";

export default {
  name: "MapMenu",
  components: {
    Map,
  },
  data() {
    return {
      selected: 0,
      backSelected: false,
    };
  },
  props: {
    maps: { type: Array, requiered: true },
    displayBack: { type: Boolean, default: true },
    defaultScorePannel: { type: Number, default: 1 },
  },
  created() {
    document.addEventListener("keydown", this.keyAndler);
  },
  methods: {
    keyAndler(k) {
      if ([32, 37, 38, 39, 40].indexOf(k.keyCode) > -1) k.preventDefault();

      if (k.key == "ArrowDown") {
        this.selected += 1;
        if (this.selected >= this.maps.length) this.selected = 0;
        document.getElementById(this.maps[this.selected].name).scrollIntoView({
          behavior: "smooth",
        });
        this.$sounds.play("menu", "tick");
      }
      if (k.key == "ArrowUp") {
        this.selected -= 1;
        if (this.selected < 0) this.selected = this.maps.length - 1;
        document.getElementById(this.maps[this.selected].name).scrollIntoView({
          behavior: "smooth",
        });
        this.$sounds.play("menu", "tick");
      }
      if (k.key == "Escape") {
        this.back();
        this.$sounds.play("menu", "select");
      }
      if (k.key == "Enter") {
        this.select();
      }
    },
    play(i) {
      this.$emit("select", this.maps[i].id);
    },
    demoAi(i) {
      this.$emit("selectDemoAi", this.maps[i].id);
    },
    select() {
      this.$sounds.play("menu", "select");
      if (this.backSelected) this.back();
      else this.$emit("select", this.maps[this.selected].id);
    },
    back() {
      this.$emit("back");
    },
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyAndler);
  },
};
</script>


<style scoped>
#MapMenu {
  scroll-behavior: smooth;
  display: flex;
}
#maps {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.map {
  /* border: solid 0.2vh var(--third); */
  transition: all 0.1s ease-in;
}

.map.back {
  width: 15vh;
  padding: 1vh;

  align-self: flex-start;
}
</style>