<template>
  <div>
    Loading
    Creating the Game
  </div>
</template>

<script>
export default {
  created() {
    // check game integrity
    if (this.$route.params.mapId == undefined) {
      console.error("Map ID is requiered");
      console.error(this.$route.params);
      this.$router.push("/");
    } else {
      this.$backendDialog.createGame("ai", this.$route.params.mapId).then((game) => {
        this.$store.commit("setGameId", game.id);
        this.$router.push({ name: "BoardAi", params: { game } });
      });
    }
  },
};
</script>

<style>
</style>