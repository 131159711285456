<template>
  <div id="Endless">
    <h1>
      <button class="red" @click="back">&#9668; Back</button> Select a dungeon
    </h1>
    <MapMenu
      id="menu"
      :maps="dungeons"
      v-on:select="play"
      v-on:selectDemoAi="demoAi"
      v-on:back="back"
    />
  </div>
</template>

<script>
export default {
  name: "Endless",

  data() {
    return {
      loading: true,
      dungeons: [],
    };
  },
  created() {
    // check if retry wanted
    if (this.$route.query.retry && this.$route.query.mapId !== undefined)
      this.play(this.$route.query.mapId);
    else {
      // Load dungeons
      this.$store.commit("setGameType", "solo");
      this.$store.commit("setGameMode", "Endless");
      this.$backendDialog.getDungeonMaps().then((dungeons) => {
        this.dungeons = dungeons;
        this.loading = false;
      });
    }
  },
  methods: {
    back() {
      this.$router.push("/Singleplayer");
    },
    play(selectedMapId) {
      this.$router.push({
        name: "GameCreation",
        params: { mapId: selectedMapId },
      });
    },

    demoAi(selectedMapId) {
      console.log("demo ai", selectedMapId);
      this.$router.push({
        name: "GameCreationAi",
        params: { mapId: selectedMapId },
      });
    },

  },
};
</script>

<style scoped>
#Endless {
  display: flex;
  flex-direction: column;
}
h1 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
</style>