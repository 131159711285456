<template>
  <transition name="fade">
    <div id="AssetLoading" v-if="loading">Loading game assets</div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  name: "AssetLoading",
  mounted() {
    this.$services.loadAllAssets().then(() => {
      console.log("Images loaded");
      this.loading = false;
    });
  },
};
</script>

<style scoped>
#AssetLoading {
  font-size: 2vh;
  padding: 0 1vh 0 1vh;
  margin: 1vh;
  position: absolute;
  color: white;
  border: solid white 0.1vh;
  border-radius: 1vh;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>