<template>
  <div id="Board">
    <div id="midPannel">
      <div id="infoPannel">
        <!-- Current score -->
        <div class="score" v-if="gameMode !== 'versus'">
          Score :
          <br />
          <b>{{ score }}</b>
        </div>
        <!-- World's best  -->
        <div class="score">
          <span> World's best : </span>
          <br />
          <b>{{ worldBestScore }}</b>
        </div>
        <div>
          {{ game.map.name }}
          <br />
          <u style="font-size:0.55em">
          {{ modelName }}
          </u>
        </div>
        <button class="blue" @click="aiSelection = !aiSelection">
          Select AI
        </button>
        <button class="red" @click="leave">Leave</button>
      </div>

      <div id="gameAndPlayers">
        <div id="leftPannel" class="playersPannel" v-if="!$services.isMobile">
          <Player
            id="p1"
            v-if="players[0] !== undefined"
            :player="players[0]"
            v-on:itemClick="selectItem"
          />
          <Player
            id="p2"
            v-if="players[1] !== undefined"
            :player="players[1]"
            v-on:itemClick="selectItem"
          />
        </div>
        <!-- All Canvas -->
        <div id="centerPannel">
          <div id="canvasGroup">
            <canvas
              id="hud"
              style="z-index: 4"
              :width="board.sizeX"
              :height="board.sizeY"
            />
            <canvas
              id="animations"
              style="z-index: 3"
              :width="board.sizeX"
              :height="board.sizeY"
            />
            <canvas
              id="front"
              style="z-index: 2"
              :width="board.sizeX"
              :height="board.sizeY"
            />
            <canvas
              id="entity"
              style="z-index: 1"
              :width="board.sizeX"
              :height="board.sizeY"
            />
            <canvas
              id="ground"
              style="z-index: 0"
              :width="board.sizeX"
              :height="board.sizeY"
            />
          </div>
        </div>
        <div id="rightPannel" class="playersPannel">
          Demonstration speed :
          <button
            @click="updateDemoSpeed('x1')"
            :class="demoSpeed == 'x1' ? 'green' : 'blue'"
          >
            x1
          </button>
          <button
            @click="updateDemoSpeed('x2')"
            :class="demoSpeed == 'x2' ? 'green' : 'blue'"
          >
            x2
          </button>
          <button
            @click="updateDemoSpeed('x5')"
            :class="demoSpeed == 'x5' ? 'green' : 'blue'"
          >
            x5
          </button>
          <button
            @click="updateDemoSpeed('step')"
            :class="demoSpeed == 'step' ? 'green' : 'blue'"
          >
            Step
          </button>
          <button
            @click="autoRety = !autoRety"
            :class="autoRety ? 'green' : 'blue'"
          >
            Auto retry
          </button>
        </div>
      </div>
    </div>
    <div id="botomPannel">
      <div id="leftPannel" class="playersPannel" v-if="$services.isMobile">
        <Player
          id="p1"
          v-if="players[0] !== undefined"
          :player="players[0]"
          v-on:itemClick="selectItem"
        />
        <Player
          id="p2"
          v-if="players[1] !== undefined"
          :player="players[1]"
          v-on:itemClick="selectItem"
        />
      </div>
      <div
        id="rightPannel"
        class="playersPannel"
        v-if="$services.isMobile && players.length > 2"
      >
        <Player
          id="p3"
          v-if="players[2] !== undefined"
          :player="players[2]"
          v-on:itemClick="selectItem"
        />
        <Player
          id="p4"
          v-if="players[3] !== undefined"
          :player="players[3]"
          v-on:itemClick="selectItem"
        />
      </div>
    </div>

    <!-- Game over pannel -->
    <GameoverScreen
      v-if="gameOver"
      :scoreDetails="scoreDetails"
      @retry="retry"
      @menu="menu"
      transition="fade"
    />
    <!-- Combo Displayer -->
    <ComboDisplayer :combo="combo" />

    <!-- AI selection modal -->
    <model-selector
      v-show="aiSelection"
      v-on:cancel="aiSelection = false"
      v-on:modelSelect="
        gameOver = false;
        aiSelection = false;
      "
      :currentMap="game.map.name"
    />
  </div>
</template>

<script>
import Player from "../board/Player";
import GameoverScreen from "../board/GameoverScreen";
import ComboDisplayer from "../board/ComboDisplayer";
import ModelSelector from "./ModelSelector.vue";

export default {
  name: "Board",
  components: { Player, GameoverScreen, ComboDisplayer, ModelSelector },
  data() {
    return {
      game: null,
      gameMode: null,
      score: 0,
      combo: 0,
      scoreDetails: null,
      players: [],

      gameBestScore: null, // To Display the current player(s) best score
      worldBestScore: null,

      selectedItem: "",
      sending: false,
      gameOver: false,

      // AI
      modelName: "",
      aiSelection: false,
      demoSpeed: "x1",
      autoRety: false,
    };
  },
  created() {
    // check game integrity
    if (!this.$route.params.game) {
      console.error("Game is requiered");
      console.error(this.$route.params);
      this.$router.push("/");
    } else {
      // Variable
      let bpm = 120;
      this.animationInterval = 1000000 / (bpm * 60);
      this.effectAnimationInterval = 50;

      if (this.$services.isMobile) {
        this.board = {
          sizeX: Math.min(window.innerWidth / 1.1, window.innerHeight / 1.1),
          sizeY: Math.min(window.innerWidth / 1.1, window.innerHeight / 1.1),
        };
      } else {
        this.board = {
          sizeX: window.innerHeight / 1.2,
          sizeY: window.innerHeight / 1.2,
        };
      }

      this.game = this.$route.params.game;

      // Set player(s) best score and world best to display
      if (this.game.gameMode === "solo" || this.game.gameMode === "ai") {
        this.worldBestScore = this.game.map.soloScores[0]
          ? this.game.map.soloScores[0].score
          : 0;

        this.modelName = this.game.modelName;
      }

      if (this.game.gameMode === "coop") {
        this.worldBestScore = this.game.map.coopScores[
          this.game.players.length
        ][0]
          ? this.game.map.coopScores[this.game.players.length][0].score
          : 0;
      }

      this.scores = this.game.map.coopScores;

      this.players = this.game.players;

      this.floor = this.game.map.floor;
      this.gameMode = this.game.gameMode;
      this.playerSpawn = this.game.map.playerSpawn;

      this.nextMove = null;
      this.animation = null;
      this.effectAnimation = null;
      this.effectAnimations = [];

      this.board.dimX = this.game.map.board.dimX;
      this.board.dimY = this.game.map.board.dimY;
      // Set canvas size
      if (this.board.dimX > this.board.dimY) {
        // long board
        this.tileSize = this.board.sizeX / this.board.dimX;
      } else {
        // tall board
        this.tileSize = this.board.sizeY / this.board.dimY;
      }
      this.board.offsetX =
        (this.board.sizeX -
          this.tileSize *
            (this.board.dimX - (this.$services.isMobile ? 0 : 1))) /
        2;
      this.board.offsetY =
        (this.board.sizeY - this.tileSize * this.board.dimY) / 2;
    }
  },
  mounted() {
    // init
    this.hudCtx = document.getElementById("hud").getContext("2d");
    this.hudCtx.imageSmoothingEnabled = false;
    this.hudCtx.webkitImageSmoothingEnabled = false;
    this.hudCtx.msImageSmoothingEnabled = false;
    this.hudCtx.font = this.tileSize / 2 + "px Geo";
    this.hudCtx.textAlign = "center";

    this.animationsCtx = document.getElementById("animations").getContext("2d");
    this.animationsCtx.imageSmoothingEnabled = false;
    this.animationsCtx.webkitImageSmoothingEnabled = false;
    this.animationsCtx.msImageSmoothingEnabled = false;

    this.frontCtx = document.getElementById("front").getContext("2d");
    this.frontCtx.imageSmoothingEnabled = false;
    this.frontCtx.webkitImageSmoothingEnabled = false;
    this.frontCtx.msImageSmoothingEnabled = false;

    this.entityCtx = document.getElementById("entity").getContext("2d");
    this.entityCtx.imageSmoothingEnabled = false;
    this.entityCtx.webkitImageSmoothingEnabled = false;
    this.entityCtx.msImageSmoothingEnabled = false;

    this.groundCtx = document.getElementById("ground").getContext("2d");
    this.groundCtx.imageSmoothingEnabled = false;
    this.groundCtx.webkitImageSmoothingEnabled = false;
    this.groundCtx.msImageSmoothingEnabled = false;

    // this.debugMap()

    // Draw maps
    let fullSizeMap = [0, 0, this.board.dimX, this.board.dimY];
    this.drawImage(
      this.groundCtx,
      "dungeons/" + this.game.map.id + "/ground",
      ...fullSizeMap
    );
    this.drawImage(
      this.frontCtx,
      "dungeons/" + this.game.map.id + "/front",
      ...fullSizeMap
    );

    // draw spawns
    if (this.game.gameMode !== "solo") {
      this.game.map.playerSpawns.forEach((spawn) => {
        this.drawImage(
          this.groundCtx,
          "objects/spawns/" + spawn.color,
          spawn.x,
          spawn.y
        );
      });
    }

    this.game.map.monsterSpawns.forEach((spawn) => {
      this.drawImage(
        this.groundCtx,
        "objects/spawns/monster",
        spawn.x,
        spawn.y
      );
    });

    // draw entitys
    this.drawEntitys();

    // Start idle animations
    this.startAnimations();

    // controls
    document.addEventListener("keydown", this.keyandler);
  },
  methods: {
    drawSquare(context, x, y, sizeX = 1, sizeY = 1, color) {
      context.beginPath();
      // context.rect(x, y, size, size);
      context.fillStyle = color;
      context.fillRect(
        this.board.offsetX + x * this.tileSize,
        this.board.offsetY + y * this.tileSize,
        sizeX * this.tileSize,
        sizeY * this.tileSize
      );
      context.stroke();
    },
    drawImage(context, image, x, y, sizeX = 1, sizeY = 1) {
      context.drawImage(
        this.$services.getImage(image),
        this.board.offsetX + x * this.tileSize,
        this.board.offsetY + y * this.tileSize,
        sizeX * this.tileSize,
        sizeY * this.tileSize
      );
    },

    clearEntitys() {
      this.entityCtx.clearRect(0, 0, this.board.sizeX, this.board.sizeY);
    },
    drawEntitys() {
      // chests
      this.game.chests.forEach((t) => {
        if (!t.dead)
          this.drawImage(this.entityCtx, "objects/chests/0", t.pos.x, t.pos.y);
        if (t.dead && t.timeBeforeSpawn <= 3)
          this.drawImage(
            this.entityCtx,
            "animations/apparationSymbol/" + t.timeBeforeSpawn,
            t.pos.x,
            t.pos.y
          );
      });
      // traps
      this.game.traps.forEach((t) => {
        this.drawImage(
          this.entityCtx,
          "objects/traps/" + t.loop,
          t.pos.x,
          t.pos.y
        );
      });
      // monsterSpawns
      this.game.monsterSpawns.forEach((t) => {
        if (t.monsterSpawning && t.timeBeforeSpawn <= 3) {
          // draw preview
          this.drawImage(
            this.entityCtx,
            "animations/apparationSymbol/" + t.timeBeforeSpawn,
            t.pos.x,
            t.pos.y
          );
        }
      });

      // items
      this.game.items.forEach((i) => {
        if (i.animationLoop >= 4) i.animationLoop = 0;
        i.animationLoop += 1;

        if (i.type == "arrow") {
          this.drawImage(
            this.entityCtx,
            "objects/arrow/" + i.heading + "/" + i.animationLoop,
            i.pos.x + 0.1,
            i.pos.y,
            0.8,
            0.8
          );
          if (i.animationLoop >= 4) i.animationLoop = 0;
        }
        if (i.type == "bomb") {
          this.drawImage(
            this.entityCtx,
            "objects/" +
              i.sousType +
              "/" +
              i.timeBeforeBoom +
              "/" +
              i.animationLoop,
            i.pos.x + 0.1,
            i.pos.y,
            0.8,
            0.8
          );
        }
      });
      // monsters
      this.game.monsters.forEach((m) => {
        m.animationLoop += 1;

        this.drawImage(
          this.entityCtx,
          "monsters/" +
            m.avatar +
            "/idle/" +
            m.direction +
            "/" +
            m.animationLoop,
          m.pos.x,
          m.pos.y - 1.15,
          1,
          2
        );

        if (m.animationLoop >= 4) {
          m.animationLoop = 0;
        }
      });

      // Players
      this.players
        .filter((p) => !p.dead)
        .forEach((p) => {
          p.animationLoop += 1;

          this.drawImage(
            this.entityCtx,
            "avatars/" +
              p.avatar +
              "/idle/" +
              p.direction +
              "/" +
              p.animationLoop,
            p.pos.x,
            p.pos.y - 1.15,
            1,
            2
          );

          if (p.animationLoop >= 4) {
            p.animationLoop = 0;
          }
        });

      this.drawHud();
    },

    drawHud() {
      this.hudCtx.clearRect(0, 0, this.board.sizeX, this.board.sizeY);

      if (this.game.gameMode !== "solo") {
        // pos Cursor
        this.players.forEach((p) => {
          if (p.nextMove) {
            let col;
            if (!p.selectedItem) col = "rgba(0, 255, 0, 0.35)";
            else col = "rgba(0, 25, 200, 0.5)";
            this.drawSquare(
              this.hudCtx,
              p.nextMove.x + 0.1,
              p.nextMove.y + 0.1,
              0.8,
              0.8,
              col
            );
          }
        });
        // Players Name
        this.players
          .filter((p) => !p.dead)
          .forEach((p) => {
            // this.hudCtx.fillStyle = "rgba(255, 255, 0, 0.5)";
            // if (p.publicId == this.playerId) {
            // Self
            this.hudCtx.fillStyle = "rgba(255, 255, 255, 0.8)";
            // } else {
            //   // Ally
            //   if (p.team == this.playerTeam)
            //     this.hudCtx.fillStyle = "rgba(0, 255, 0, 0.5)";
            //   // Enemy
            //   else this.hudCtx.fillStyle = "rgba(255, 0, 0, 0.5)";
            // }

            this.hudCtx.fillText(
              p.playerName,
              this.board.offsetX + this.tileSize * p.pos.x + this.tileSize / 2,
              this.board.offsetY + this.tileSize * (p.pos.y - 0.5)
            );
          });
      }
    },

    startAnimations() {
      this.animation = setInterval(() => {
        this.clearEntitys();
        this.drawEntitys();
      }, this.animationInterval);
    },

    clearAnimations() {
      this.animationsCtx.clearRect(0, 0, this.board.sizeX, this.board.sizeY);
    },
    drawAnimations(anims) {
      anims.forEach((anim) => {
        (anim.runing = true),
          (anim.count = 0),
          this.effectAnimations.push(anim);
      });

      if (this.effectAnimations.length > 0) {
        clearInterval(this.effectAnimation);
        this.effectAnimation = setInterval(() => {
          this.clearAnimations();
          // draw all animations
          this.effectAnimations.forEach((a) => {
            this.drawAnimation(a);
          });
          // remove done animations
          this.effectAnimations = this.effectAnimations.filter((a) => a.runing);
          if (this.effectAnimations.length == 0) {
            clearInterval(this.effectAnimation);
          }
        }, this.effectAnimationInterval);
      }
    },
    drawAnimation(animation) {
      animation.count += 1;
      let image = this.$services.getAnimationImage(
        "animations/" + animation.name + "/" + animation.count
      );

      if (!image) {
        animation.runing = false;
        return;
      }

      this.animationsCtx.drawImage(
        image,
        this.board.offsetX +
          (animation.pos.x - (animation.dimX - 1)) * this.tileSize,
        this.board.offsetY +
          (animation.pos.y - (animation.dimY - 1)) * this.tileSize,
        animation.dimX * this.tileSize,
        animation.dimY * this.tileSize
      );
    },
    playSounds(events) {
      events.forEach((e) => {
        if (e.type == "swordHit") this.$sounds.play("game", "swordHit");
        if (e.type == "explosion") this.$sounds.play("game", "explosion");
        if (e.type == "monsterDeath") this.$sounds.play("game", "monsterDeath");
        if (e.type == "playerDeath") this.$sounds.play("game", "damage");
        if (e.type == "chestOpen") this.$sounds.play("game", "chestOpen");
        if (e.type == "loot") this.$sounds.play("game", "newItem");
        if (e.type == "playerStep") this.$sounds.play("game", "playerStep");
        if (e.type == "playerItemPlacement")
          this.$sounds.play("game", "playerItemPlacement");
      });
    },
    // Controls
    keyandler(e) {
      // Menu
      if (e.key == "r" || e.key == "R") this.retry();
      if (e.key == "Escape") this.menu();
      if (e.key == " ") this.askForDemoMovement();
    },
    askForMovement(dx, dy) {
      if (!this.sending) {
        this.sending = true;
        this.$backendDialog
          .askForMovement(dx, dy)
          .finally(() => {
            this.sending = false;
          })
          .then((r) => {
            if (r.status == 200) {
              // movement OK
              this.players.find((p) => p.publicId == this.playerId).nextMove =
                r.data.nextMove;
              this.drawHud();
            } else if (r.status == 201) {
              // All movement done
            } else if (r.status == 204) {
              // Wrong movement
              // TODO : play sound
            }
          })
          .catch((e) => {
            this.$store.commit("sendMessage", {
              type: "error",
              text: "Error while sending movements",
            });
            console.error(e);
            this.menu();
          });
      }
    },
    askForDemoMovement() {
      if (!this.sending) {
        this.sending = true;
        this.$backendDialog.askForDemoMovement().finally(() => {
          this.sending = false;
        });
      }
    },
    selectItem(item) {
      if (
        item ==
        this.players.find((p) => p.publicId == this.playerId).selectedItem
      )
        item = "";

      if (!this.sending) {
        this.sending = true;
        this.$backendDialog
          .askForItemSelect(item)
          .finally(() => {
            this.sending = false;
          })
          .catch((e) => {
            if (e.response.status == 402) {
              this.$store.commit("sendMessage", {
                type: "error",
                text: "You don't have the item " + item + " !",
              });
              return;
            }
            this.$store.commit("sendMessage", {
              type: "error",
              text: "Error while sending item selection\n" + e.response.data,
            });
            console.error(e);
          });
      }
    },

    // Menu
    retry() {
      this.$backendDialog.retryDemo().then(() => {
        this.gameOver = false;
        this.startAnimations();
      });
    },
    menu() {
      this.$router.push(this.$store.state.mode);
    },
    leave() {
      if (this.gameMode == "solo" || this.gameOver) {
        this.menu();
      } else {
        // if (
        //   confirm(
        //     "Leaving a multyplayer game will results in you being not good >:("
        //   )
        // )
        this.menu();
      }
    },
    updateDemoSpeed(demoSpeed) {
      this.$backendDialog.setDemoSpeed(demoSpeed).then(() => {
        this.demoSpeed = demoSpeed;
      });
    },
  },
  sockets: {
    entityUpdate: function ({
      players,
      monsters,
      monsterSpawns,
      traps,
      chests,
      items,
      events,
      score,
      combo,
      modelName,
    }) {
      this.players = players;
      this.game.monsterSpawns = monsterSpawns;
      this.game.monsters = monsters;
      this.game.traps = traps;
      this.game.chests = chests;
      this.game.items = items;
      this.nextMove = null;
      this.clearEntitys();
      this.drawEntitys();

      this.drawAnimations(events);
      this.playSounds(events);
      this.score = score;
      this.combo = combo;
      this.modelName = modelName;
    },
    allyUpdate: function ({ publicId, nextMove }) {
      let playerToUpdate = this.players.find((p) => p.publicId === publicId);
      if (!playerToUpdate) {
        console.warn("Player fot found with ID " + publicId);
        return;
      }
      playerToUpdate.nextMove = nextMove;
      this.drawHud();
    },
    allyItemUpdate: function ({ publicId, item }) {
      let playerToUpdate = this.players.find((p) => p.publicId === publicId);
      if (!playerToUpdate) {
        console.warn("Player fot found with ID " + publicId);
        return;
      }
      this.$set(playerToUpdate, "selectedItem", item);
      playerToUpdate.nextMove = null;
      this.drawHud();
    },
    gameOver: function ({ scoreDetails }) {
      clearInterval(this.animation);
      this.combo = 0;
      this.gameOver = true;
      this.scoreDetails = scoreDetails;
      if (this.autoRety) this.retry();
    },
    playerLeave: function (player) {
      this.$store.commit("sendMessage", {
        type: "info",
        text:
          (player.playerName ? player.playerName : "A player") +
          " has left the game",
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.animation);
    clearInterval(this.effectAnimation);
    if (!this.gameOver) this.$backendDialog.leaveGame();
    this.$store.commit("removeGameId");
    document.removeEventListener("keydown", this.keyandler);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#Board {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;
}
/* Top Pannel */
#infoPannel {
  padding: 1vh;
  display: flex;
  justify-content: space-between;
}
b {
  font-size: 4vh;
  color: yellow;
}

/* Mid Pannel */
#midPannel {
  flex: 2;
}
#gameAndPlayers {
  display: flex;
}
#leftPannel,
#rightPannel {
  display: flex;
  justify-content: space-evenly;
}
.playersPannel {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
}
#centerPannel {
  flex: 1;
}
#canvasGroup {
  position: relative;
  display: flex;
  justify-content: center;
}
canvas {
  position: absolute;
  image-rendering: crisp-edges;
}

/* botom Pannel */

#botomPannel {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Arrows for mobile */
#arrows {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: fadein 2s ease-in;
}
#arrows #mid {
  flex: 1;
  display: flex;
  justify-content: center;
}
.touchKey {
  flex: 1;
  display: flex;
  border-radius: 5vh;
  justify-content: center;
  align-items: center;
  background-color: darkslategrey;
  margin: 1vh;
  margin-top: 0.1vh;
  font-size: 5vh;
}
.touchKey:hover,
.touchKey:active {
  background-color: rgb(56, 129, 129);
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  #Board {
    flex-direction: row;
  }
  #infoPannel * {
    font-size: 3vw;
    white-space: nowrap;
  }
}

@media screen and (orientation: portrait) {
  #infoPannel {
    padding: 0vh;
  }
  #infoPannel * {
    font-size: 5vw;
    white-space: nowrap;
  }
}
</style>
