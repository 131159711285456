<template>
  <div>
    <div v-if="score == null" id="noScore">No score yet</div>
    <div v-else id="score">
      <!-- Rank -->
      <div class="data" id="rank" v-if="showRank">
        #<b>{{ score.rank }}</b>
      </div>

      <!-- Player(s) name -->
      <div id="account" v-if="score.accounts">
        <!-- Coop score -->
        <div class="name" v-for="account in score.accounts" :key="account.id">
          {{ account.name }}
        </div>
      </div>
      <div id="account" v-else>
        <!-- Solo score -->
        {{ score.accountName }}
      </div>
      <!-- <div class="data" id="gameMode">{{ score.gameMode }}</div> -->
      <div class="data" id="scoreValue">
        score: <b>{{ score.score }}</b>
      </div>
      <div class="data" id="time">
        {{ $services.prettyTimeStamp(score.timeStamp) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    score: { type: Object, requiered: true },
    showRank: { type: Boolean, default: true },
  },
};
</script>

<style scoped>
#noScore {
  color: red;
  font-weight: bold;
}
#score {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid lightgreen 0.15vh;
  border-radius: 1vh;
}

.data {
  padding-left: 0.9vh;
  font-size: 2vh;
  white-space: nowrap;
}
.data:last-child {
  padding-right: 0.9vh;
}

/* Values */
#time {
  font-size: 1.5vh;
  /* color: rgb(68, 68, 68); */
}

#account {
  color: lightgreen;
  font-weight: bold;
  padding-left: 1vh;
}
</style>