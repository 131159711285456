<template>
  <div id="Menu">
    <div id="backSection">
      <button
        v-if="displayBack"
        :class="'choice back ' + (backSelected ? 'selected' : '')"
        @mouseover="backSelected = true"
        @focus="backSelected = true"
        @click="back"
      >
        &#9668; Back
      </button>
    </div>
    <div id="choices">
      <button
        :class="
          'choice' +
          (!backSelected && selected == i ? ' selected' : '') +
          (!choice.available ? ' unavailable' : '')
        "
        v-for="(choice, i) in choices"
        :key="choice.name"
        @mouseover="over(i)"
        @focus="over(i)"
        @click="select"
      >
        {{ choice.name }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      selected: 0,
      backSelected: false,
    };
  },
  props: {
    choices: { type: Array, requiered: true },
    displayBack: { type: Boolean, default: true },
  },
  created() {
    document.addEventListener("keydown", this.keyAndler);
  },
  methods: {
    keyAndler(k) {
      if (k.key == "ArrowDown") {
        this.selected += 1;
        if (this.selected >= this.choices.length) this.selected = 0;
        this.$sounds.play("menu", "tick");
      }
      if (k.key == "ArrowUp") {
        this.selected -= 1;
        if (this.selected < 0) this.selected = this.choices.length - 1;
        this.$sounds.play("menu", "tick");
      }
      if (k.key == "ArrowLeft") {
        this.backSelected = true;
        this.$sounds.play("menu", "tick");
      }
      if (k.key == "ArrowRight") {
        this.backSelected = false;
        this.$sounds.play("menu", "tick");
      }
      if (k.key == "Escape") {
        this.back();
      }
      if (k.key == "Enter") {
        this.select();
      }
    },
    select() {
      if (this.backSelected) this.back();
      else if (this.choices[this.selected].available) {
        this.$emit("select", this.choices[this.selected].name);
        this.$sounds.play("menu", "select");
      } else {
        this.$sounds.play("menu", "error");
      }
    },
    back() {
      this.$sounds.play("menu", "select");
      this.$emit("back");
    },
    over(index) {
      this.selected = index;
      this.backSelected = false;
      this.$sounds.play("menu", "tick");
    },
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyAndler);
  },
};
</script>

<style  scoped>
#menu {
  display: flex;
}
#choices {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.choice {
  width: 40vh;
  padding: 2vh;
  margin-bottom: 1vh;
  background-color: var(--secondary);
  /* border: solid 0.2vh var(--third); */
  box-shadow: 0px 0px 5px 0px black;
  transition: all 0.1s ease-in;
}

.choice.back {
  width: 15vh;
  padding: 1vh;

  align-self: flex-start;
}

.choice.selected {
  transform: scale(1.03);
  background-color: var(--fourth);
}

.choice.unavailable {
  filter: brightness(0.4);
}

@media screen and (orientation: portrait) {
  #menu {
    flex-direction: column;
  }
}

</style>