<template>
  <div id="tutorial">
    <button class="red" id="back" @click="back">&#8678; Back</button>
    <div id="cont">
      <h1>Tutorial</h1>
      <img src="../../../assets/images/tutorial/gameplay.png" alt />
      <h2>Movements</h2>
      <p>
        Move with the arrows [
        <b class="key"> &#8593; </b>
        <b class="key"> &#8592; </b>
        <b class="key"> &#8595; </b>
        <b class="key"> &#8594; </b>
        ] or with [
        <b class="key"> z </b>
        <b class="key"> q </b>
        <b class="key"> s </b>
        <b class="key"> d </b>
        ] on your keyboard
      </p>
      <h2>It's a turn based game</h2>
      <p>
        The enemies, the traps, the items and the other players wont move as
        long as you don't move
      </p>
      <p>So take your time !</p>
      <h2>Avoid the traps</h2>
      <div class="imgList">
        <div class="imgLegend">
          <img
            src="../../../assets/images/tutorial/traps2.png"
            class="small"
            alt
          />
          <p>Safe !</p>
        </div>
        <div class="imgLegend">
          <img
            src="../../../assets/images/tutorial/traps3.png"
            class="small"
            alt
          />
          <p>Safe !</p>
        </div>
        <div class="imgLegend">
          <img
            src="../../../assets/images/tutorial/traps1.png"
            class="small"
            alt
          />
          <p>Not safe !</p>
        </div>
      </div>
      <h2>Kill the monsters</h2>
      <p>
        Killing monsters bring you points and allow you to survive a little bit
        longer.
      </p>
      <p>
        To kill the monsters, just walk over them. But be careful not be be
        walked over by a monster!
      </p>
      <div class="imgList">
        <div class="imgLegend">
          <img
            src="../../../assets/images/tutorial/killMonster1.png"
            class="small"
            alt
          />
          <p>You kill the monster</p>
        </div>
        <div class="imgLegend">
          <img
            src="../../../assets/images/tutorial/killMonster2.png"
            class="small"
            alt
          />
          <p>The monster kills you</p>
        </div>
      </div>
      <h2>Combo</h2>
      <p>
        Killing multiple enemies in a row will create a combo and bring extra
        points !
      </p>
      <div class="imgList">
        <img
          src="../../../assets/images/tutorial/combo1.png"
          class="verrySmall"
          alt
        />
        <img
          src="../../../assets/images/tutorial/combo2.png"
          class="verrySmall"
          alt
        />
        <img
          src="../../../assets/images/tutorial/combo3.png"
          class="verrySmall"
          alt
        />
      </div>
      <br />
      <div class="imgList">
        <table>
          <tr>
            <td>Combo :</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
            <td>9</td>
            <td>10</td>
            <td>...</td>
          </tr>
          <tr>
            <td>Extra points :</td>
            <td>2</td>
            <td>5</td>
            <td>9</td>
            <td>14</td>
            <td>20</td>
            <td>27</td>
            <td>35</td>
            <td>44</td>
            <td>...</td>
          </tr>
        </table>
      </div>
      <br />
      <p>
        Placing a bomb, throwing an arrow or opening a chest won't break your
        combo
      </p>
      <h2>Items</h2>
      <p>
        <img src="../../../assets/images/tutorial/items.png" />
        <br>
        You can use differents items during a game.
        <br>
      </p>
            <h3>How to use them ?</h3>
      <p>
        <ol>
          <li>
            Select the item you want to use with [
              <b class="key"> 1 </b>
              <b class="key"> 2</b>
              <b class="key"> 3 </b>
            ] or by clicking on it
          </li>
          <li>
            Move in the direction you want to use the item
          </li>
        </ol>
      </p>
            <h3>Why use them ?</h3>
      <p>
        <ol>
          <li>
            To kill a lot of monsters
          </li>
          <li>
            To keep a combo running
          </li>
          <li>
            To stay in place
          </li>
          <li>
            To save your life
          </li>
        </ol>
      </p>

      <h3>The arrows</h3>
        <img
          :src="$API_URL + 'images/objects/arrow/inventoryIcon.png'"
          class="verrySmall item"
        />
      <p>
        The arrows will kill the first player or monter that it hit.
        They can go over chests.
        <br>
        The arrows will aslo detonate bombs and dynamite if they hit one
      </p>
      <h3>The bombs and the dynamites</h3>
      <div class="imgList">
        <div class="imgLegend">
        <img
          :src="$API_URL + 'images/objects/bomb/inventoryIcon.png'"
          class="verrySmall item"
        />
          <p>Bomb</p>
      </div>
        <div class="imgLegend">
        <img
          :src="$API_URL + 'images/objects/dynamite/inventoryIcon.png'"
          class="verrySmall item"
        />
          <p>Dynamite</p>
      </div>
      </div>
      <p>
        The bombs and the dynamites take 3 turn to explode. Once they explode, they destroy everything on their explosion zone
      </p>
      <div class="imgList">
        <div class="imgLegend">
          <img
            src="../../../assets/images/tutorial/bomb1.png"
            class="verrySmall"
            alt
          />
          <p>The bomb will explode in 3 turns</p>
        </div>
        <div class="imgLegend">
          <img
            src="../../../assets/images/tutorial/bomb2.png"
            class="verrySmall"
          />
          <p>The bomb will explode in 2 turns</p>
        </div>
        <div class="imgLegend">
          <img
            src="../../../assets/images/tutorial/bomb3.png"
            class="verrySmall"
          />
          <p>The bomb explode next turn !</p>
        </div>
      </div>
            <p>
        The differences between the bombs and the dynamites is there explosion zone :
      </p>
      <div class="imgList">
        <div class="imgLegend">
          <img
            src="../../../assets/images/tutorial/bombZone.png"
          />
          <p>The bomb explosion zone highlighted</p>
        </div>
        <div class="imgLegend">
          <img
            src="../../../assets/images/tutorial/dynamiteZone.png"
          />
          <p>The dynamite explosion zone highlighted</p>
        </div>
      </div>
      <p>
        If a bomb is in another bomb explosion zone when it detonate, it will also detonate, creating a chain reaction
      </p>
      <h2>Chests</h2>
      <p>
        Opening a chest makes you :
        <ol>
          <li>Win extra points</li>
          <li>Keep your combo</li>
          <li>Stay in place</li>
          <li>Give a chance to win a random item</li>
        </ol>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    document.addEventListener("keydown", this.keyAndler);
  },
  methods: {
    keyAndler(k) {
      if (k.key == "Escape") {
        this.back();
      }
    },
    back() {
      this.$sounds.play("menu", "select");
      this.$router.push({ name: "Welcome" });
    },
  },
  computed: {},
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyAndler);
  },
};
</script>

<style scoped>
#tutorial {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
#cont {
  background-color: rgb(160, 160, 160);
  color: black;
  padding: 2vh;
  overflow-y: scroll;
  width: 70vw;
}
ul {
  padding-bottom: 2vh;
}
h2 {
  color: white;
  background-color: rgb(78, 78, 78);
  margin-bottom: 2vh;
  margin-top: 1vh;
}
h3 {
  text-decoration: underline;
  margin-bottom: 2vh;
  margin-top: 1vh;
}
.key {
}
.imgList {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 2vh 0vh 2vh 0vh;
}

img.small {
  height: 10vh;
}

img.verrySmall {
  height: 5vh;
}
img.item {
  background: white;
  padding: 1vh;
  margin: 1vh;
  border-radius: 1vh;
}

#back {
  position: fixed;
  left: 0;
}

table {
  border: solid black 0.1vh;
}

td {
  text-align: right;
  padding-left: 2vw;
  /* border: solid black 0.1vh */
}

@media (max-width: 1400px) {
  #cont {
    width: 100vw;
  }
}
</style>