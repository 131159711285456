<template>
  <Modal style="z-index: 200">
    <div id="ModelSelector">
      <h2>
        Select a model
        <button @click="cancel" class="red">Cancel</button>
      </h2>
      <div id="modelList">
        <div class="model" v-for="model in models" v-bind:key="model.name">
          <button class="modelName green" @click="selectModel(model)">
            {{ model.name }}
          </button>

          <div
            class="statistics"
            v-if="
              model.statistics && currentMap && model.statistics[currentMap]
            "
          >
            <span>
              Mean:
              <b>{{ model.statistics[currentMap].mean }}</b>
            </span>

            <span style="opacity: 0.4">
              Max: <b>{{ model.statistics[currentMap].max }} </b></span
            >
          </div>

          <button
            class="blue"
            v-if="model.trainingInfo"
            @click="modelTrainingToInfo = model"
          >
            i
          </button>
        </div>
      </div>
      <Modal v-if="modelTrainingToInfo">
        <div id="modelTrainingToInfo">
          <h2>
            Model training info
            <button @click="modelTrainingToInfo = null" class="red">
              Close
            </button>
          </h2>
          <!-- Tree display of the trainingInfo:  -->
          <div id="trainingInfoTree">
            <JSONView
              :data="modelTrainingToInfo.trainingInfo"
              rootKey="trainingInfo"
              :maxDepth="10"
              :styles="{ key: 'white' }"
            />
          </div>
        </div>
      </Modal>
        <button @click="cancel" class="red">Cancel</button>
    </div>
  </Modal>
</template>

<script>
import { JSONView } from "vue-json-component";

export default {
  components: {
    JSONView,
  },
  props: { currentMap: { type: String, default: null } },
  data() {
    return {
      models: [],
      modelTrainingToInfo: null,
    };
  },
  mounted() {
    // Load the models
    this.getModels();
  },
  methods: {
    getModels() {
      this.$backendDialog
        .getModels()
        .then((models) => {
          // Sort the models by average score
          if (!this.currentMap) this.models = models;
          else {
            this.models = models.sort((a, b) => {
              if (!a.statistics || !b.statistics) return 0;
              if (!a.statistics[this.currentMap]) return 1;
              if (!b.statistics[this.currentMap]) return 0;

              if (a.statistics && b.statistics) {
                return (
                  b.statistics[this.currentMap].mean -
                  a.statistics[this.currentMap].mean
                );
              }
              return 0;
            });
          }
        })
        .catch((e) => {
          console.error(e);

          this.$store.commit("sendMessage", {
            type: "error",
            text: "Error while loading the models",
          });
        });
    },
    cancel() {
      this.$emit("cancel");
    },
    selectModel(model) {
      this.$backendDialog
        .selectModel(model.name)
        .then(() => {
          this.$emit("modelSelect");
        })
        .catch((e) => {
          console.error(e);

          this.$store.commit("sendMessage", {
            type: "error",
            text: "Error while loading the models",
          });
        });
    },
  },
};
</script>

<style scoped>
#ModelSelector {
  padding: 1vh 0 1vh 0;
  background-color: var(--primary);
  border-top: solid 1vh black;
  border-bottom: solid 1vh black;
}
.model {
  display: flex;
  margin: 0 auto;
}
.modelName {
  font-size: 0.7em;
  flex: 1;
  text-align: left;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
#modelTrainingToInfo {
  padding: 1vh 0 1vh 0;
  width: 60vw;
  background-color: var(--primary);
  border-top: solid 1vh black;
  border-bottom: solid 1vh black;
  font-size: 0.2em;
}
#trainingInfoTree {
  text-align: left;
}
.statistics {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.statistics span {
  font-size: 0.8em;
}
</style>
<style>
.json-view-item .value-key {
  color: rgb(154, 255, 238) !important;
}
.json-view-item .properties {
  display: none;
}
</style>