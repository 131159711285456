const { Howl } = require('howler');

import config from '../../config'
const apiURL = config.API_URL

const musicToLoad = ["menu"]

var musics = {}
var volume = localStorage["musicVolume"] || 0.5

function loadMusics() {
  for (const music of musicToLoad) {
    musics[music] = newMusic(music)
  }
}

function newMusic(music) {
  return new Howl({
    src: [apiURL + "musics/" + music + ".wav"],
    loop: true,
    volume: volume
  })
}

loadMusics()

export default {
  play(music) {
    if (musics[music]) musics[music].play()
    else console.warn("Music " + music + " not found");
  },
  stop() {
    for (const m in musics) {
      musics[m].stop();
    }
  },
  setVolume(vol) {
    volume = vol / 100
    for (const m in musics) {
      musics[m].volume(volume);
    }
    localStorage["musicVolume"] = volume;
  },
  getVolume() {
    return volume * 100
  },
}