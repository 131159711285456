<template>
  <div id="gameoverScreen">
    <h2>Game Over</h2>
    <div class="table">
      <div class="row">
        <div class="cell title">Turn number :</div>
        <div class="cell">{{ scoreDetails.nbTurn }}</div>
        <span class="small cell"> points </span>
      </div>
      <div class="row">
        <div class="cell title">Monster killed :</div>
        <div class="cell">{{ scoreDetails.nbKilledMonsters }}</div>
        <span class="small cell"> points </span>
      </div>
      <div class="row">
        <div class="cell title">Onpened chests :</div>
        <div class="cell">{{ scoreDetails.nbOpenedChests }}</div>
        <span class="small cell"> points </span>
      </div>
      <div class="row">
        <div class="cell title">Combo bonus :</div>
        <div class="cell">{{ scoreDetails.comboScore }}</div>
        <span class="small cell"> points </span>
      </div>
      <div id="total" class="row">
        <div class="cell title">Total score :</div>
        <div class="cell">{{ scoreDetails.totalScore }}</div>
        <span class="small cell"> points </span>
      </div>
    </div>
    <div style="display: flex">
      <button class="yellow" @click="retry">
        <span>Retry</span>
        <div class="key" v-if="!$services.isMobile">r</div>
      </button>
      <button class="red" @click="menu">
        <span>Menu</span>
        <div class="key" v-if="!$services.isMobile">esc</div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scoreDetails: { type: Object, requiered: true },
  },
  mounted() {},
  methods: {
    retry() {
      this.$emit("retry");
    },
    menu() {
      this.$emit("menu");
    },
  },
};
</script>
<style>
#gameoverScreen {
  z-index: 100;
  position: fixed;
  background: var(--primary);
  height: 35vh;
  width: 40vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0vh 0vh 10vh 0vh rgba(255, 255, 255, 0.459);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  animation: fadein 1s ease-out;

}
#gameoverScreen .table {
  display: table;
}
#gameoverScreen H2 {
  padding: 2vh 0 1vh 0;
}

#gameoverScreen .row {
  display: table-row;
}
#gameoverScreen .title {
  font-weight: bold;
  text-align: right;
  padding-right: 2vh;
}
#gameoverScreen .row .cell {
  display: table-cell;
}
#gameoverScreen .row .small {
  padding-left: 1vh;
  font-size: 2vh;
  opacity: 50%;
}
#gameoverScreen #total {
  color: red;
}
#gameoverScreen button {
  display: flex;
}
#gameoverScreen button.yellow {
  color:black;
}
</style>