<template>
  <div :class="'player ' + diplomacy">
    <!-- Player name -->
    <u>{{ player.playerName }}</u>
    <!-- Player team -->
    <span class="team">Team : {{ player.team }}</span>
    <div v-if="player.dead" id="deadSymbol">☠</div>

    <!-- Player inventory -->
    <div id="inventory">
      <!-- arrow -->
      <div
        :class="
          'itemType' +
          (player.selectedItem == 'arrow' ? ' selected' : '') +
          (!player.inventory.arrow ? ' empty' : '')
        "
      >
        <div class="itemNumber">{{ player.inventory.arrow }}</div>
        <img
          :class="diplomacy == 'self' ? 'icon selectable' : 'icon'"
          :src="$API_URL + 'images/objects/arrow/inventoryIcon.png'"
          title="Arrow"
          @click="itemClick('arrow')"
        />
        <div class="key" v-if="diplomacy == 'self' && !$services.isMobile">
          1
        </div>
      </div>
      <!-- bomb -->
      <div
        :class="
          'itemType' +
          (player.selectedItem == 'bomb' ? ' selected' : '') +
          (!player.inventory.bomb ? ' empty' : '')
        "
      >
        <div class="itemNumber">{{ player.inventory.bomb }}</div>
        <img
          :class="diplomacy == 'self' ? 'icon selectable' : 'icon'"
          :src="$API_URL + 'images/objects/bomb/inventoryIcon.png'"
          title="Bomb"
          @click="itemClick('bomb')"
        />
        <div class="key" v-if="diplomacy == 'self' && !$services.isMobile">
          2
        </div>
      </div>
      <!-- dynamite -->
      <div
        :class="
          'itemType' +
          (player.selectedItem == 'dynamite' ? ' selected' : '') +
          (!player.inventory.dynamite ? ' empty' : '')
        "
      >
        <div class="itemNumber">{{ player.inventory.dynamite }}</div>
        <img
          :class="diplomacy == 'self' ? 'icon selectable' : 'icon'"
          :src="$API_URL + 'images/objects/dynamite/inventoryIcon.png'"
          title="Dynamite"
          @click="itemClick('dynamite')"
        />
        <div class="key" v-if="diplomacy == 'self' && !$services.isMobile">
          3
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Player",
  props: {
    player: {
      type: Object,
      requiered: true,
    },
    update: {
      type: Boolean,
    },
  },
  methods: {
    itemClick(item) {
      if (this.diplomacy == "self") this.$emit("itemClick", item);
    },
  },
  computed: {
    selectedItem() {
      return this.player.selectedItem;
    },
    diplomacy() {
      if (this.player.publicId == this.$store.state.playerId) return "self";
      if (this.player.team == this.$store.state.playerTeam) return "ally";
      return "enemy";
    },
  },
};
</script>

<style scoped>
.player {
  display: flex;
  flex-direction: column;
  padding: 1vh;
  background-color: rgb(225, 0, 255);
  animation: fadein 2s ease-in;
}

.self {
  background-color: white;
  color: black;
}
.ally {
  background-color: greenyellow;
  color: black;
}
.enemy {
  background-color: rgb(255, 0, 0);
  color: white;
}

.team {
  font-size: 0.8em;
}
#deadSymbol {
  font-size: 2em;
  animation: shake 6s linear infinite;
}

/* inventory  */
#inventory {
  display: flex;
  flex-direction: column;
  padding: 1vh;
}
.itemNumber {
  font-size: 4vh;
  font-weight: bold;
}
.itemType {
  display: flex;
  align-items: center;
}
.itemType + .itemType {
  padding-top: 1vh;
}
.itemType.selected .icon {
  transform: scale(1.5);
}
.itemType.empty {
  opacity: 40%;
}

.icon {
  position: relative;
  height: 4vh;
  image-rendering: pixelated;
  transition: transform 0.2s;
}

.selectable {
  cursor: pointer;
}
.selectable:hover {
  transform: scale(1.5);
  z-index: 100;
}

/* Help  */
.helpRow {
  display: flex;
  margin-top: 1vh;
  justify-content: space-between;
}

@media (max-width: 1000px) {
  .itemNumber {
    font-size: 50px;
  }
  .icon {
    height: 50px;
  }
  u {
    font-size: 40px;
  }
}
</style>