<template>
  <modal @cancel="cancel">
    <div id="register">
      <form v-on:submit.prevent>
        <h2>Register</h2>
        <div class="rowList">
          <div class="row">
            <div class="rowTitle">Ingame name :</div>
            <input type="text" maxlength="15" v-model="registerName" required />
          </div>
          <div class="row">
            <div class="rowTitle">Username :</div>
            <input type="text" v-model="registerUsername" required />
          </div>
          <div class="row">
            <div class="rowTitle">Password :</div>
            <input type="password" v-model="registerPassword" required />
          </div>
        </div>
        <button type="submit" @click="register" id="registerBtn">Register</button>
      </form>
      <button @click="cancel" id="cancelBtn">Cancel</button>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      // register form
      registerName: "",
      registerUsername: "",
      registerPassword: "",
    };
  },
  mounted() {
    // document.getElementById("usernameInput").focus()
  },
  methods: {
    register() {
      if (!this.$services.nameCorrect(this.registerName)) return;

      if (this.registerUsername.length < 2) {
        this.$store.commit("sendMessage", {
          type: "error",
          text: "Your username need to exist a litle bit more",
        });
        return;
      }
      if (this.registerPassword.length < 2) {
        this.$store.commit("sendMessage", {
          type: "error",
          text: "Your password need to grow a litle bit more",
        });
        return;
      }

      this.$backendDialog
        .register({
          name: this.registerName,
          username: this.registerUsername,
          password: this.registerPassword,
        })
        .then((c) => {
          this.$store.commit("setAccount", c.account);
          this.$store.commit("sendMessage", {
            type: "success",
            text: "Welcome in !",
          });
          this.$emit("registered");
        })
        .catch((e) => {
          console.log(e.response);

          if (e.response.status == 402) {
            this.$store.commit("sendMessage", {
              type: "error",
              text: "This username is not available.",
            });
          } else {
            console.error(e);
            this.$store.commit("sendMessage", {
              type: "error",
              text: "Error while registering\n" + e.response.data,
            });
          }
        });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
#register {
  padding: 1vh 0 1vh 0;
  width: 100vw;
  background-color: var(--primary);
  border-top: solid 1vh black;
  border-bottom: solid 1vh black;
}

#registerBtn {
  background-color: var(--secondary);
  width: 30vh;
}
</style>